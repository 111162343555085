import { Component, OnInit, Input } from '@angular/core';

export interface NotificationBannerMessage {
  message?: string;
  type?: string;
  daterange?: string[];
}

@Component({
  selector: 'common-notification-banner',
  templateUrl: './notification-banner.component.html',
  styleUrls: ['./notification-banner.component.scss'],
})
export class NotificationBannerComponent implements OnInit {
  @Input() bannerMessage = '';
  @Input() bannerType = 'info'; // important, emergency, info
  @Input() startDate?: Date;
  @Input() endDate?: Date;

  private _messageList;
  @Input() set messageList(notificationArray: NotificationBannerMessage[]) {
    //only run this if they haven't set a banner message the old way
    if (!this._messageList) {
      if (!this.hasActiveBanner && notificationArray?.length > 0) {
        notificationArray.some((messageItem: NotificationBannerMessage) => {
          const message = messageItem.message;
          const type = messageItem.type;

          const arrayDates = messageItem.daterange as string[];
          let messageStartDate: Date = null;
          let messageEndDate: Date = null;
          if (arrayDates?.length === 2 && message && type) {
            const startdate = arrayDates[0];
            const enddate = arrayDates[1];
            if (startdate) {
              messageStartDate = new Date(startdate);
            }
            if (enddate) {
              messageEndDate = new Date(enddate);
            }
            //if notification message falls in the current date range, set the banner dates/messages accordingly
            const currentDate = new Date();
            const startDateInRange = !messageStartDate || this.dateInRange(currentDate, messageStartDate, null);
            const endDateInRange = !messageEndDate || this.dateInRange(currentDate, null, messageEndDate);
            if (startDateInRange && endDateInRange) {
              //set notification parameters
              this.startDate = messageStartDate;
              this.endDate = messageEndDate;
              this.bannerMessage = message;
              this.bannerType = type;

              // This will break the loop. We just want to grab the first message that matches this condition even if there are multiple
              return true;
            }
          }
          return false; // Continue looping if the condition is not met
        });
        this._messageList = notificationArray;
      }
    }
  }

  bannerVisible = true;
  constructor() {}

  ngOnInit(): void {
    // console.log(this.messageList);
  }

  dateInRange(dateToCheck: Date, startDate: Date = null, endDate: Date = null) {
    let inRange = true; //by default
    if (startDate && startDate > dateToCheck) {
      inRange = false;
    } else if (endDate && endDate < dateToCheck) {
      inRange = false;
    }
    return inRange;
  }

  get formattedBannerMessage(): string {
    return `${this.bannerMessage}`;
  }

  get inDateRange() {
    const currentDate = new Date();
    return this.dateInRange(currentDate, this.startDate, this.endDate);
  }

  get hasActiveBanner() {
    return this.bannerMessage && this.inDateRange;
  }
}
