export const CASE_MANAGEMENT_TITLES = {
  Search: 'Search',
  Reminders: 'Reminders',
  Assignments: 'Assignments',
  Settings: 'Settings',
  Reports: 'Reports',
  Team: 'Team',
  InformationServices: 'Information  Service',
};

export const CASE_MANAGEMENT_ADMIN_TITLES = {
  UsersAndRoles: 'Users & Roles',
  Restrictions: 'Restrictions',
  AdminSettings: 'Admin Settings',
};

export const INTAKE_MANAGEMENT_TITLES = {
  Search: 'Search',
  ASCC: 'ASCC',
  ProvincialAssignmentQueue: 'Provincial queue',
  RegionalAssignmentQueue: 'Regional queue',
  ApplicationsMissingIdentifiers: 'Missing identifiers',
  CEC: 'CEC',
  Settings: 'Settings',
};

export const TITLES_SUFFIX = {
  CaseManagement: 'Case Management',
  IntakeManagement: 'Intake Management',
};

export const CASE_MANAGEMENT_CLIENT_TITLES = {
  ActionPlan: 'Action plan',
  IntakeAssessment: 'Intake & assessment',
  PersonalInfo: 'Personal info',
  IncomeAndAssets: 'Income & assets',
  Income: 'Income',
  Employment: 'Employment',
  Education: 'Education',
  Comments: 'Comments',
  Reminders: 'Reminders',
  History: 'History',
  Audit: 'Audit',
};
