export const ServiceLocations = [
  { _id: 'airdrie-sc', name: 'Airdrie Alberta Supports Centre' },
  { _id: 'athabasca-sc', name: 'Athabasca Alberta Supports Centre' },
  { _id: 'barrhead-sc', name: 'Barrhead Alberta Supports Centre' },
  { _id: 'bonnyville-sc', name: 'Bonnyville Alberta Supports Centre' },
  { _id: 'brooks-sc', name: 'Brooks Alberta Supports Centre' },
  { _id: 'calgary-e-sc', name: 'Calgary East - Westland Alberta Supports Centre' },
  { _id: 'calgary-n-sc', name: 'Calgary North – One Executive Place Alberta Supports Centre' },
  { _id: 'calgary-s-sc', name: 'Calgary South – Heritage Square Alberta Supports Centre' },
  { _id: 'calgary-lancore-sc', name: 'Calgary – Lancore Alberta Supports Centre' },
  { _id: 'camrose-sc', name: 'Camrose Alberta Supports Centre' },
  { _id: 'canmore-sc', name: 'Canmore Alberta Supports Centre' },
  { _id: 'claresholm-sc', name: 'Claresholm Alberta Supports Centre' },
  { _id: 'cochrane-sc', name: 'Cochrane Alberta Supports Centre' },
  { _id: 'cold-lake-sc', name: 'Cold Lake Alberta Supports Centre' },
  { _id: 'crowsnest-pass-sc', name: 'Crowsnest Pass Alberta Supports Centre' },
  { _id: 'drayton-valley-sc', name: 'Drayton Valley Alberta Supports Centre' },
  { _id: 'drumheller-sc', name: 'Drumheller Alberta Supports Centre' },
  { _id: 'edmonton-c-sc', name: 'Edmonton Central – City Centre Alberta Supports Centre' },
  { _id: 'edmonton-n-sc', name: 'Edmonton North – Northgate Alberta Supports Centre' },
  { _id: 'edmonton-s-sc', name: 'Edmonton South – Mill Woods Alberta Supports Centre' },
  { _id: 'edmonton-w-sc', name: 'Edmonton West – Meadowlark Alberta Supports Centre' },
  { _id: 'edson-sc', name: 'Edson Alberta Supports Centre' },
  { _id: 'fort-mcmurray-sc', name: 'Fort McMurray Alberta Supports Centre' },
  { _id: 'grande-prairie-sc', name: 'Grande Prairie Alberta Supports Centre' },
  { _id: 'high-level-sc', name: 'High Level Alberta Supports Centre' },
  { _id: 'high-prairie-sc', name: 'High Prairie Alberta Supports Centre' },
  { _id: 'high-river-sc', name: 'High River Alberta Supports Centre' },
  { _id: 'hinton-sc', name: 'Hinton Alberta Supports Centre' },
  { _id: 'lac-la-biche-sc', name: 'Lac La Biche Alberta Supports Centre' },
  { _id: 'leduc-sc', name: 'Leduc Alberta Supports Centre' },
  { _id: 'lethbridge-sc', name: 'Lethbridge Alberta Supports Centre' },
  { _id: 'lloydminster-sc', name: 'Lloydminster Alberta Supports Centre' },
  { _id: 'medicine-hat-sc', name: 'Medicine Hat Alberta Supports Centre' },
  { _id: 'olds-sc', name: 'Olds Alberta Supports Centre' },
  { _id: 'parkland-sc', name: 'Parkland Alberta Supports Centre' },
  { _id: 'peace-river-sc', name: 'Peace River Alberta Supports Centre' },
  { _id: 'pincher-creek-sc', name: 'Pincher Creek Alberta Supports Centre' },
  { _id: 'red-deer-sc', name: 'Red Deer Alberta Supports Centre' },
  { _id: 'rocky-mountain-sc', name: 'Rocky Mountain House Alberta Supports Centre' },
  { _id: 'sherwood-park-sc', name: 'Sherwood Park Alberta Supports Centre' },
  { _id: 'slave-lake-sc', name: 'Slave Lake Alberta Supports Centre' },
  { _id: 'st-albert-sc', name: 'St. Albert Alberta Supports Centre' },
  { _id: 'st-paul-sc', name: 'St. Paul Alberta Supports Centre' },
  { _id: 'stettler-sc', name: 'Stettler Alberta Supports Centre' },
  { _id: 'taber-sc', name: 'Taber Alberta Supports Centre' },
  { _id: 'vegreville-sc', name: 'Vegreville Alberta Supports Centre' },
  { _id: 'vermilion-sc', name: 'Vermilion Alberta Supports Centre' },
  { _id: 'Wainwright-sc', name: 'Wainwright Alberta Supports Centre' },
  { _id: 'westlock-sc', name: 'Westlock Alberta Supports Centre' },
  { _id: 'wetaskiwin-sc', name: 'Wetaskiwin Alberta Supports Centre' },
  { _id: 'whitecourt-sc', name: 'Whitecourt Alberta Supports Centre' },
];
