import { PreInterventionEmploymentDetailsTypes } from '../case-management/preInterventionEmploymentDetailsTypes';
import {
  AccessToInternetScale,
  AccessToTelephoneScale,
  NoYesScale,
  NoYesScaleWithNa,
  OneToFiveScale,
  OneToFiveScaleWithNA,
  YesNoScale,
} from './assessment-scales';
import { SkillsLookup } from './skills-lookup.interface';

export const EraPersonalInformationSkills: { [name: string]: SkillsLookup } = {
  housingStability: {
    displayValue: 'Housing stability',
    helperText: `Housing stability refers to the living situation of an individual or family. A person experiencing homelessness is without stable, safe, permanent, appropriate housing, or the immediate prospect, means and ability to acquiring it. Not everyone experiences homelessness the same, every situation is unique.`,
    responseDefinitions: [
      'Unhoused - homeless, couch surfing.',
      'At risk of eviction or utility disconnection, accessing short-term shelter but no immediate long-term housing options, unable to manage ongoing shelter costs, rental arrears.',
      'Current housing is stable but not suitable to their circumstances (i.e., disability adaptation, health or safety), in transitional housing and requires long term housing or subsidized housing, has secured housing and needs support to obtain.',
      "Has stable and long-term housing, no risks to current housing, may want to move but doesn't  need to; managing rent and utilities monthly, accessing subsidy.",
      'Long term sustainable housing, does not require subsidy, has a network of support, needs one time issue for an event, not ongoing benefits, ability to choose shelter options.',
      'N/A - Unable to answer. I.e., client did not provide information.',
    ],
    sampleQuestions: [
      { question: 'Tell me about your living situation.' },
      { question: 'Where are you living right now?' },
      { question: 'Do you have, or are you on a lease?' },
    ],
    scale: OneToFiveScaleWithNA,
  },
  communitySupports: {
    displayValue: 'Community supports',
    helperText:
      'Community supports systems may be organized through extended family members, neighbours, religious organizations, community programs, cultural, and ethnic organizations, or other support groups or organizations.',
    responseDefinitions: [
      'Unaware of supports available or process to access, newcomer, lack of identified support network, history of unsuccessful stakeholder interactions.',
      'Has some knowledge but is not or cannot connect. limited support network.',
      'Is aware of resources and process to access. effective support network.',
      'Presents with familiarity with most community resources including accessing support network.',
      'Strong support network, able to access as required for improvement in their quality of life.',
      'N/A - Unable to answer i.e., client did not provide information.',
    ],
    sampleQuestions: [
      { question: 'Tell me about supports you have in your community.' },
      { question: 'What steps could you take now to start to widen your circle of support.' },
    ],
    scale: OneToFiveScaleWithNA,
  },
  accessToTransportation: {
    displayValue: 'Access to transportation',
    helperText:
      'Transportation refers to all the various methods people use to travel around, with attention being focused on getting to training or to workplaces. In urban centres public transportation is usually reliable and cost-effective. However, shift work and worksites not located on public transit routes can create difficulties for individuals. Small towns and rural areas can be a greater challenge for people without a vehicle or the use of a vehicle.',
    sampleQuestions: [
      { question: 'How will you get to college or work?' },
      { question: 'What is your usual method of transportation?' },
      { question: 'How reliable is your current way of getting from here to there?' },
      { question: 'Have you looked into all the alternative modes of transportation that are available?' },
      { question: `What is your back-up plan in case your car breaks down or isn’t available to you?` },
    ],
    scale: NoYesScaleWithNa,
  },
  hasAnId: {
    displayValue: 'Has an ID',
    helperText: `Photo ID is an identity document that includes a photograph of the holder, usually only their face. The most commonly accepted forms of photo ID are those issued by government authorities such as a driver’s license or passport. ID is often required by workplaces or training programs to confirm the identity of the person.`,
    sampleQuestions: [{ question: 'What kind of ID do you have?' }],
    scale: NoYesScale,
  },
  accessToInternet: {
    displayValue: 'Access to internet',
    helperText:
      'Access to the internet and email is essential to job search and acceptance into training programs. The access may be at home or outside of the home. Public access refers to free wifi in public spaces such as libraries and coffee shops. It may also refer to wifi at a friend or family member’s home.',
    sampleQuestions: [
      {
        question:
          'Tell me about what kind of access you have to internet (free public wifi, paid internet, mobile data).',
      },
      { question: 'Are you able to access free internet in public spaces like libraries, cafes, etc.?' },
      { question: 'How do employers contact you now?' },
      { question: 'How do you receive messages?' },
    ],
    scale: AccessToInternetScale,
  },
  accessToTelephone: {
    displayValue: 'Access to telephone',
    helperText:
      'A telephone and message system is essential to job search and acceptance into training programs. A person’s access may be at home or outside of the home. Public access refers to use of telephones in public spaces such as libraries and community agencies. It may also refer to telephone access at a friend or family member’s phone number.',
    sampleQuestions: [
      {
        question: 'Tell me about what kind of access you have to a telephone.',
      },
      { question: 'Do you have, and know how to use, voicemail?' },
      { question: 'How do employers contact you now?' },
      { question: 'How do you receive messages?' },
    ],
    scale: AccessToTelephoneScale,
  },
  movedToAlberta: {
    displayValue: 'Moved to Alberta in the last 24 months?',
    scale: YesNoScale,
  },
};

export const EraDependentInformationSkills: { [name: string]: SkillsLookup } = {
  hasDependents: {
    displayValue: 'Has dependents',
    scale: YesNoScale,
    subSkills: {
      accessToChildcare: {
        displayValue: 'Access to childcare',
        helperText:
          'Child care refers to the arrangements made to provide supervision and care to children. Child care options include private babysitting, daycare centres, family day homes, and before and after school care.',
        sampleQuestions: [
          { question: 'What arrangements have you made for child care?' },
          {
            question:
              'Tell me about the arrangements you have in place for child care for times when the school schedule changes, for example, early dismissals, teacher’s convention, and so on.',
          },
          { question: 'What child care is available to you when your child is ill?' },
          {
            question:
              'How will your work schedule fit with your caregiver’s schedule especially with regard to drop-off and pick-up times for your children?',
          },
          { question: 'Where is your caregiver located in relation to your home and work?' },
        ],
        scale: NoYesScaleWithNa,
      },
    },
  },
};

export const EraEmploymentSkills: { [name: string]: SkillsLookup } = {
  interviewSkills: {
    displayValue: 'Interview skills',
    helperText:
      'The interview skills factor involves having an understanding of current interviewing practices and the ability to prepare effectively for an interview.',
    responseDefinitions: [
      'Has little to no interview experience, lack of interview knowledge.',
      'Limited interview experience or history of unsuccessful interviews, requires additional supports. May have disability impacting interview skills.',
      'Some interview experience & can prepare for interview, but may benefit from short-term guidance/refresher on interview skills (i.e., switching job fields).',
      'Good interview experience & history of successful interviews, confidence with interview skills and/or experience with interview prep.',
      'Significant interview experience & strong history of successful interviews, solicits and applies feedback from interviews, high confidence & experience with interview styles & skills.',
      'N/A - Never worked, unable to work - not expected to seek a job at any point.',
    ],
    sampleQuestions: [
      {
        question: 'Tell me about some of the work that you have done previously, both paid and volunteer.',
        followupQuestions: [
          { question: 'What were the three most important skills that you needed in each of those positions?' },
        ],
      },
      { question: 'What are the top five skills that you bring to an employer?' },
      {
        question: 'What positions have you recently applied for?',
        followupQuestions: [
          { question: 'What appeals to you about the work involved in each?' },
          { question: 'What skills do you anticipate are needed for each position?' },
          { question: 'Explain how your skills relate to those needed in these positions.' },
        ],
      },
      {
        question:
          'Tell me about a time that you felt good about something that you accomplished at work, either paid or volunteer. (Identify skills and knowledge demonstrated in the accomplishments and give this information back to the individual).',
        followupQuestions: [
          {
            question: 'How might you use these skills and knowledge areas in the positions that you are applying for?',
          },
        ],
      },
      {
        question: 'Are there any areas you anticipate might be problematic in an interview?',
        followupQuestions: [{ question: 'How might you prepare to handle those most effectively?' }],
      },
      {
        question: 'How do you usually prepare for interviews?',
        followupQuestions: [
          { question: 'What has worked well for you in that approach?' },
          { question: 'What, if anything, might you want to change to enhance your interview preparation?' },
        ],
      },
    ],
    scale: OneToFiveScaleWithNA,
  },
  jobSeekingSkills: {
    displayValue: 'Job seeking skills',
    helperText:
      'Job seeking and finding techniques involve the understanding of and ability to use various work search methods.',
    responseDefinitions: [
      'Little to no job search history, not aware of job search resources and strategies.',
      'May have limited job search history or is unsuccessful in current job search approach. Some networking, such as getting jobs via word of mouth. Only familiar with certain job search streams i.e., unfamiliar with digital platforms.',
      'Some job search history, has used a few job search platforms but may benefit from additional supports, open to accessing an agency, has network they can access.',
      'Good job search history and work experience, often successful in finding work via job search & network, identifies various job search practices and platforms, adapts job search to available industries.',
      'Consistent work history, ability to locate a job quickly, has strong network, engaging with various job search platforms, excels with digital services.',
      'N/A - Never worked, unable to work - not expected to seek a job at any point.',
    ],
    sampleQuestions: [
      { question: 'What have you done so far in your work search?' },
      {
        question: 'Describe the type of work you are looking for.',
        followupQuestions: [
          { question: 'What has led you to focus on that particular area of work?' },
          { question: 'What is it that appeals to you in this type of work?' },
        ],
      },
      {
        question: 'How are you feeling about your work search?',
        followupQuestions: [
          {
            question:
              'What kinds of things might you do to feel better, if you get discouraged or down with your job search?',
          },
        ],
      },
      {
        question: 'What has worked for you before to find a job?',
      },
    ],
    scale: OneToFiveScaleWithNA,
  },

  workReferences: {
    displayValue: 'Work references',
    helperText:
      'Work references are former and/or current employers who would be willing and able to speak to an individual’s experience, skills, accomplishments, and work ethic.',
    responseDefinitions: [
      'No professional or personal references, little to no work history, or may have negative references.',
      'Minimal work history with limited and/or outdated references. Or they have references but had past difficulties with employers.',
      'Has 1-2 positive employment references from their supervisors, overall good work history.',
      'Has 3+ positive employment references which includes current supervisor, great network & work history.',
      'Has a wealth of positive references who can speak to multiple competencies, strong work history, strong network of contacts.',
      'N/A - Never worked, unable to work - not expected to seek a job at any point',
    ],
    sampleQuestions: [
      {
        question: 'List three work references that you can provide to a potential employer.',
        followupQuestions: [
          { question: 'What might each of these references say about you and your skills and strengths?' },
        ],
      },
      {
        question:
          'List five of the most important skills and/or strengths that will be required for the type of work you are targeting.',
        followupQuestions: [
          { question: 'What jobs have you held that have involved these skills and strengths?' },
          { question: 'Which of your work references will be best suited to recommend your abilities in these areas?' },
        ],
      },
      {
        question: 'Do you have any concerns about providing work references and what they might say?',
        followupQuestions: [
          { question: 'What are your concerns?' },
          { question: 'How might you address these concerns?' },
        ],
      },
    ],
    scale: OneToFiveScaleWithNA,
  },
  hasAResume: {
    displayValue: 'Has a resume?',
    helperText:
      'Resumes should be visually appealing and accurately convey one’s skills, strengths, and accomplishments to convince an employer to grant the writer an interview.',
    sampleQuestions: [
      {
        question: 'Do you presently have a resume?',
        followupQuestions: [{ question: 'When did you last update it?' }, { question: 'When did you last use it?' }],
      },
      {
        question: 'What are the key skills, background, and experience required in the type of work you are seeking?',
        followupQuestions: [
          { question: 'How do your skills, background, and experience relate?' },
          { question: 'How are these key areas represented in your resume?' },
        ],
      },
      {
        question:
          'Tell me about some of your accomplishments in your previous work (Identify skills and knowledge demonstrated in the accomplishments and give this information back to the individual).',
        followupQuestions: [{ question: 'How might you include these skills in your resume?' }],
      },
    ],
    scale: NoYesScaleWithNa,
  },
  appliedForEI: {
    displayValue: 'Recently applied for EI?',
    helperText: '',
    scale: YesNoScale,
  },
  ableToWork: {
    displayValue: 'Able to work?',
    helperText: '',
    scale: YesNoScale,
    subSkills: {
      typeOfWork: {
        displayValue: 'Type of work',
        helperText: '',
        textField: true,
        footerText: 'e.g. clerical, construction',
      },
    },
  },
  criminalRecord: {
    displayValue: 'Criminal record',
    helperText:
      'Various websites define a criminal record as a list of crimes for which an accused person has previously been convicted. Individuals with criminal records may face serious employment barriers. The unemployment rate for ex-offenders is about three times the national average. An individual on parole is four times more likely to return to prison when unemployed. Some types of employment require criminal record checks and bonding applications. Crimes involving property may make bonding costs prohibitive, thus preventing employment in certain fields.',
    sampleQuestions: [
      { question: 'What challenges do you face in managing a criminal record?' },
      { question: 'Tell me what you have been doing to become a part of the community.' },
      { question: 'How has your criminal record influenced your job search so far?' },
      { question: 'What do you think an employer needs to hear from you in order to consider you for the job?' },
    ],
    scale: YesNoScale,
  },
  preInterventionEmploymentDetails: {
    displayValue: 'Pre-intervention employment details',
    helperText: '',
    options: PreInterventionEmploymentDetailsTypes,
  },
};

export const EraEducationSkills: { [name: string]: SkillsLookup } = {
  financialLiteracy: {
    displayValue: 'Financial literacy',
    helperText:
      'Financial literacy refers to a person’s ability to manage financial resources in order to meet financial obligations such as payments and to cover ongoing expenses such as food, housing, and personal items. Effectively managing debt as well as disposable money is another facet of financial literacy. The ability to use numbers to perform calculations and estimations and to complete tasks such as handling cash, budgeting, measuring, and analyzing is a part of financial literacy.',
    responseDefinitions: [
      'Lack required math/financial skills to secure entry level employment. Lack math and financial skills to manage and maintain personal finances (demonstrated by current debt/credit/banking challenges).',
      'Require longer term training or mentoring to develop math/financial skills to secure employment. Could address financial challenges with minimal to moderate supports (i.e.. financial literacy training, assistance to obtain a bank account, assistance to create budget/address debt).',
      'Have common required math/financial skills to secure a range of employment. Is able to manage and maintain personal finances with minimal challenges.',
      'Has the capacity to upgrade math / financial skills in short time period to secure specific or desired occupation.  Have basic financial and investment knowledge, and ability to obtain credit/ investments.',
      'Have specialized math / financial skills required to secure specific or desired occupation. Has a strong knowledge of savings and investments, budgeting and financial decisions.',
      'N/A - Has financial administrator. Trustee or public trustee.',
    ],
    sampleQuestions: [
      { question: 'How would you rate yourself in terms of your ability to work with numbers and money?' },
      {
        question:
          'How do you like working with numbers and math - statistics and measurements and other areas that require those skills?',
      },
      {
        question: 'What monthly income do you require to meet your present financial needs?',
        followupQuestions: [
          {
            question:
              'How does that amount relate to the amount of money you anticipate earning in the work that you are seeking?',
          },
        ],
      },
    ],
    scale: OneToFiveScaleWithNA,
  },
  oralCommunicationSkills: {
    displayValue: 'Oral communication skills',
    helperText:
      'Oral communication skills involve the use of speech to give and exchange thoughts and information. They include the ability to listen for understanding and to speak the language in which business is conducted.',

    responseDefinitions: [
      'No ability to communicate verbally without a 3rd party/interpreter; not currently accessing ESL.',
      'Limited ability to communicate verbally; typically require a 3rd party to assist them to communicate due to language or cognitive barrier; require longer term training (i.e., ESL), complex supports (DRES assistive tech), or limited natural supports.',
      'Working knowledge of English language, able to complete application forms but may require support/guidance, ability to access and use translation tools with direction.',
      'Able to express and explain situation/needs/skills, able to manage applications and navigate process, no demonstrated barriers.',
      'Able to effectively articulate and advocate for themselves, able to communicate through conflict, communication skills are an asset; multiple languages.',
      'N/A - Insufficient information to assess.',
    ],
    sampleQuestions: [
      {
        question:
          'On a scale of 1-5, how would you rate your ability to communication with co-workers? Supervisors? Customers?',
        followupQuestions: [{ question: 'What might you do to move that rating up a notch?' }],
      },
      { question: 'Tell me about a time that you used your oral communication skills successfully.' },

      {
        question:
          'What feedback have you received regarding your communication skills, either on the job, while volunteering, or in your everyday life?',
      },

      {
        question:
          'Describe the way you might be required to use your oral communication skills in the work that you are seeking.',
        followupQuestions: [{ question: 'How do you think your skills relate to those required?' }],
      },
    ],
    scale: OneToFiveScaleWithNA,
  },
  digitalSkills: {
    displayValue: 'Digital skills',
    helperText:
      'Digital skills includes skills required to effectively manage basic technology such as voice messaging, automated banking systems and computers encountered in everyday life and work. It also includes using the internet for electronic communication as well as a basic research tool and use of simple software to open, read, and create documents.',
    responseDefinitions: [
      'No computer/ smartphone literacy and no access, no natural supports to assist.',
      'Limited computer/smartphone capacity, has accessed public resources, would need support to set up email/passwords, able to complete online tasks with guidance.',
      'Has available access and can navigate internet, email, social media, online banking (may need tip sheet). Can navigate online applications (concept/language/attachments)',
      'Capable to manage independently, owns or has consistent access (not public access) and can manage excel, word, online applications.',
      'Owns or consistent private access and strong internet connection, adept with hardware and software, has specific digital skills related to employment (i.e., AutoCAD), confident with virtual interactions (interviews/training/ appointments).',
    ],
    sampleQuestions: [
      {
        question:
          'In what ways have you used technology in your previous work, volunteer experiences, and/or your everyday life?',
      },
      {
        question: 'Are you familiar with using a computer, tablet, smart phone?',
        followupQuestions: [
          { question: 'How have you used a computer, tablet, smart phone?' },
          { question: 'What tasks can you perform using this technology?' },
        ],
      },
      {
        question:
          'What technology or digital skills might you require related to your employment and/or training goals?',
      },
      {
        question:
          'How does your present level of skills using technology compare to the level you think you will need?',
      },
    ],
    scale: OneToFiveScaleWithNA,
  },
  otherEssentialSkills: {
    displayValue: 'Other essential skills',
    helperText:
      'A combination of reading, writing, and thinking skills. While individually these answers can vary, this is meant to capture an overall rating of the combination of these skills. Does the person’s ability to read, comprehend, and use written materials create a barrier for them? Writing involves the ability to write effectively in the language in which business is conducted. It includes writing texts and writing in documents for example: completing forms as well as non-paper-based writing like typing on a computer. Thinking skills include knowing how to problem solve, make decisions, plan and organize tasks, find information, and make effective use of memory.',
    responseDefinitions: [
      'Demonstrates inability to read or write. I.e., disability, language barrier and will not improve with intervention, requires support for day-to-day activities.',
      'Has learning disability and is unaware of supports available and limited natural supports to assist.',
      'Demonstrates an ability to manage day to day however may have learning disability, able to understand basic documents and follow directions but needs support with certain tasks.',
      'Identifies as capable in managing reading, writing and math, able to manage daily life/employment expectations.',
      'Demonstrates strong skills in reading, writing and math, confident in managing daily life/employment expectations, demonstrates lifelong learning approach',
    ],
    sampleQuestions: [
      { question: 'How comfortable is it for you to answer these questions in English?' },
      { question: 'What types of reading appeal to you?' },
      { question: 'What feedback have you received on your writing skills?' },
      {
        question: 'Tell me about your school experience.',
        followupQuestions: [
          { question: 'What are some of the things that you do well when it comes to learning and studying?' },
          { question: 'What do you find challenging when learning new things?' },
        ],
      },
    ],
    scale: OneToFiveScaleWithNA,
  },
};

export const EraHealthSkills: { [name: string]: SkillsLookup } = {
  physicalHealth: {
    displayValue: 'Physical health',
    helperText:
      'Physical health is impacted by a variety of factors including genetic and environmental, as well as personal choices made regarding diet and nutrition, exercise, and other life activities.',
    responseDefinitions: [
      'Client has permanent or unmanaged physical health issues/impacts daily functioning - possibly AISH destined - prevents employment; may or may not have a diagnosis to supports; unwilling to access supports.',
      'No family physician/access to - only access walk-in /emergency; have physical medical concerns managed with medication/treatment and needs ongoing support; may impact daily functioning; medical substantiation 6 months or more; emergency dental concerns; unaware of support.',
      'Client has minor or short-term physical health concerns that are being managed and does not affect ability to work in the long term.',
      'Identifies physically healthy with no history of recent concerns.',
      'Identifies as physically healthy with no history of concerns - no impacts to employability at all; engages in healthy living activities.',
    ],
    sampleQuestions: [
      { question: 'How would you rate your health and energy level right now?' },
      { question: 'What could you do this week that would improve your health or increase your energy levels?' },
      {
        question:
          'How do you think your physical condition might affect your ability to achieve success in the training or work that you are interested in?',
        followupQuestions: [
          { question: 'How might the work environment be altered to accommodate your condition?' },
          {
            question:
              'What are some related areas of work that you might consider that would offer greater opportunities for success?',
          },
          {
            question:
              'What kind of assistance might you require to be successful in the training program you hope to pursue?',
          },
        ],
      },
    ],
    scale: OneToFiveScaleWithNA,
  },
  mentalHealth: {
    displayValue: 'Mental health',
    helperText:
      'Mental health reflects a balance around all aspects of life; social, physical, emotional, and spiritual. It is an integral part of the individual’s overall health and well-being and has an impact on how they manage their surroundings and their life choices. Mental health goes beyond the absence of mental illness and is related to self-concept, relationships with others, and the ability to manage life effectively on a day-to-day basis.',
    responseDefinitions: [
      'Client has permanent or unmanaged mental health issues/impacts daily functioning - possibly AISH destined - prevents employment; may or may not have a diagnosis to support.',
      'Mental health issues prevent full time employment; in process of finding supports; accessing supports; undiagnosed/unmanaged mental health concerns.',
      'Client has minor or short term mental/emotional health concerns that are being managed and does not affect ability to work in the long term.',
      'Identifies no current mental health concerns. Potential history of mental health issues; is connected with supports if needed.',
      'Identifies as mentally/emotionally healthy with no history of concerns - no impacts to employability at all; engages in healthy living activities. Self aware: aware of supports and resources if need arise.',
    ],
    sampleQuestions: [
      {
        question: 'Have you ever had any concerns regarding your mental health?',
        followupQuestions: [
          {
            question: 'If yes: Tell about those concerns.',
            followupQuestions: [
              { question: 'What effect did this have on your work and life?' },
              { question: 'What have you done to address the concerns you had regarding your mental health?' },
            ],
          },
        ],
      },
      {
        question: 'What kinds of things do you do to help you get some work/life balance?',
      },
    ],
    scale: OneToFiveScaleWithNA,
  },
  familyHealth: {
    displayValue: 'Family health',
    helperText:
      'Family health focuses on the well-being of dependents and/or a spouse/partner. The physical, emotional, and mental health of family members can affect the ability of a person to attend and perform at work or school. Care of dependents can impose restrictions on the availability for work with the added stress affecting concentration and the attention paid to work and training.',
    responseDefinitions: [
      'A family member (partner, dependent) is fully reliant on client. No supports available (waitlist, severity of need).',
      'Have family care responsibilities with a known timeline of when those will come to an end (ex. child under 1, temp. health concern).',
      'Family has support in place but may be limited. No current family obligations keeping from employment/training.',
      'Have multiple supports in place to manage family responsibilities.',
      'Reports no family health issues/concerns/requirements to provide full time supports to another family member.',
    ],
    sampleQuestions: [
      {
        question: 'Tell me about your family and your responsibilities to your family.',
        followupQuestions: [
          {
            question: 'How might these responsibilities impact on the work or training that you are planning to enter?',
          },
        ],
      },
      { question: 'How has your family impacted on your work or training in the past?' },
      { question: 'What does your family do to support you?' },
    ],
    scale: OneToFiveScaleWithNA,
  },
  addictions: {
    displayValue: 'Addictions',
    helperText:
      'Addiction involves a psychological and/or physiological dependence on a drug. Severe symptoms may present when the addicted person stops taking the drug. Addictive behaviour functions to produce pleasure and/or relief from internal discomfort and is characterized by powerlessness to control the behaviour as well as continuation of the behaviour in the face of significant negative consequences.',
    responseDefinitions: [
      'Active addictions - no supports in place; no access to supports; unwilling to access supports; impacts to daily functions.',
      'Support network is not healthy support network - needs to be connected with formal supports that will be beneficial to recovery; is open to recovery activities.',
      'Have access to supports, willing to access them; intermittent participation as needed.',
      'Connection to positive supports; actively participating; preventing minimal relapse.',
      'Any supports that are successfully preventing relapse and allowing participant to function fully in employment. Connection to informal healthy supports in their community.',
    ],
    sampleQuestions: [
      { question: 'How does your (drinking) (use of drugs) (gambling) affect your work and your life?' },
      { question: 'What steps have you taken to deal with your addiction?' },
      {
        question:
          'How might your participation in a treatment plan impact your participation in the work or training that you plan to enter?',
      },
    ],
    subSkills: {
      addictionSupport: {
        displayValue: 'Addiction support',
        helperText:
          'Treatment services and supports can help people experiencing harms from substance use or addiction. Services can include early identification and intervention, harm reduction approaches, and relapse prevention. There is no one-size-fits-all treatment option, each person’s experience is unique.',
        responseDefinitions: [
          'Active addictions - no supports in place; no access to supports; unwilling to access supports; impacts to daily functions.',
          'Support network is not healthy support network - needs to be connected with formal supports that will be beneficial to recovery; is open to recovery activities.',
          'Have access to supports, willing to access them; intermittent participation as needed.',
          'Connection to positive supports; actively participating; preventing minimal relapse.',
          'Any supports that are successfully preventing relapse and allowing participant to function fully in employment. Connection to informal healthy supports in their community.',
        ],
        sampleQuestions: [
          {
            question: 'What kinds of addiction supports have you accessed in the past?',
            followupQuestions: [{ question: 'What was the outcome?' }],
          },
          { question: 'What steps have you taken to deal with your addictions? What more can you do?' },
        ],
        scale: OneToFiveScale,
      },
    },
    scale: YesNoScale,
  },
  disability: {
    displayValue: 'Is there a disability that is a barrier to employment or education?',
    scale: YesNoScale,
    helperText:
      'A functional impairment or disability is a loss of some ability to function independently. A functional impairment may result in personal limitations of activities as well as barriers to gaining or maintaining employment.<br/><br/>Functional impairments, either visible or invisible, are major factors to consider when helping an individual research and prepare learning and work paths.',
    sampleQuestions: [
      { question: 'Tell me more about the kinds of things you have difficulty doing.' },
      {
        question:
          'How have you managed to carry on with this disability that you have described in your past studies/activities/work?',
      },
      { question: 'How have you been using any assistive technology on-the-job or at your courses?' },
      { question: 'What kinds of work arrangements are best for you?' },
    ],
    subSkills: {
      medicalRecommended: {
        displayValue: 'Medical recommended?',
        scale: {
          Ratings: [
            { value: 1, displayValue: '207' },
            { value: 2, displayValue: 'Note' },
            { value: 3, displayValue: 'No' },
          ],
          BadRatings: [1, 2],
        },
        usedBy: 'internal',
      },
      appliedForAish: {
        displayValue: 'Applied for AISH?',
        scale: { ...NoYesScale, BadRatings: [1, 2] },
        usedBy: 'internal',
      },
    },
  },
};
