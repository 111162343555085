export const DOCUMENT_FRAGMENT = `
  documentCategory
  documentType
  key
  name
  size
  completed
`;

export const DOCUMENT_LIST_FRAGMENT = `
  optOut
  documents {
    ${DOCUMENT_FRAGMENT}
  }  
`;
