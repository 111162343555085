import { Lookup } from '../lookup.interface';
import { ActionPlanItemOutcomes, OutcomeLookup } from './action-plan-item-outcomes';
export interface ActionPlanActivityLookup extends Lookup {
  categories: string[]; // more than one, UI will show category drop down control
  additionalInformationOptions?: string[]; //If list not null, show additional information
  additionalInformationOptionsByCategory?: { [name: string]: string[] }; // get option list by category, if option list not null, show additional information.
  additionalControls?: { controlName: string; displayValue: string; isRequired?: boolean }[]; // this is controls for additional fields
  // additionalControlsByAdditionalInformation?: {
  //   [name: string]: {additionalControls: { controlName: string; displayValue: string; isRequired?: boolean }[]};
  // };
  wcbAcknowledgement?: boolean;
  isDRESActivity?: boolean;
  exposureCourse?: boolean;
  outcomeOptions?: OutcomeLookup[];
  sinRequired?: boolean;
  labourMarketRequired?: boolean;
  assessmentRequired?: boolean;
  usedBy?: string; // usedBy: undefined or null- means used by internal and external service provider; interal - internal staff; serviceProvider: external service Provider; other value - not used in compass, like: !css
}
export const ActionPlanItemOutcomeOptions1 = Object.values(ActionPlanItemOutcomes).filter(
  (value) =>
    ![
      ActionPlanItemOutcomes.notApproved.code,
      ActionPlanItemOutcomes.approved.code,
      ActionPlanItemOutcomes.completedServices.code,

      ActionPlanItemOutcomes.startedServices.code,

      ActionPlanItemOutcomes.incomplete.code,
      ActionPlanItemOutcomes.clientNotProgressing.code,
      ActionPlanItemOutcomes.clientReferredToDifferentProgram.code,
    ].includes(value.code)
);

export const ActionPlanItemOutcomeOptions2 = Object.values(ActionPlanItemOutcomes).filter(
  (value) =>
    ![
      ActionPlanItemOutcomes.complete.code,
      ActionPlanItemOutcomes.completedServices.code,

      ActionPlanItemOutcomes.startedServices.code,

      ActionPlanItemOutcomes.incomplete.code,
      ActionPlanItemOutcomes.clientNotProgressing.code,
      ActionPlanItemOutcomes.clientReferredToDifferentProgram.code,
    ].includes(value.code)
);

export const ActionPlanItemOutcomeOptions3 = Object.values(ActionPlanItemOutcomes).filter(
  (value) =>
    ![
      ActionPlanItemOutcomes.complete.code,
      ActionPlanItemOutcomes.notApproved.code,
      ActionPlanItemOutcomes.approved.code,

      ActionPlanItemOutcomes.inProgress.code,

      ActionPlanItemOutcomes.incomplete.code,
    ].includes(value.code)
);

export const ActionPlanActivities: { [name: string]: ActionPlanActivityLookup } = {
  accessAccommodations: {
    categories: ['Non-financial Support'],
    displayValue: 'Access Accommodations',
    outcomeOptions: ActionPlanItemOutcomeOptions1,
  },
  accessAddictionsSupport: {
    categories: ['Non-financial Support'],
    displayValue: 'Access Addictions Support',
    outcomeOptions: ActionPlanItemOutcomeOptions1,
  },
  accessAlbertaLearningInformationService: {
    categories: ['Information'],
    displayValue: 'Access Alberta Learning Information Service (ALIS)',
    outcomeOptions: ActionPlanItemOutcomeOptions1,
  },
  accessCareerHotline: { categories: ['Information'], displayValue: 'Access Career Hotline', usedBy: '!css' },
  accessChildCare: {
    categories: ['Non-financial Support'],
    displayValue: 'Access Child Care',
    outcomeOptions: ActionPlanItemOutcomeOptions1,
  },
  accessCommunityResources: {
    categories: ['Financial Independence', 'Non-financial Support'],
    displayValue: 'Access Community Resources',
    outcomeOptions: ActionPlanItemOutcomeOptions1,
  },
  accessMentalHealthSupports: {
    categories: ['Non-financial Support'],
    displayValue: 'Access Mental Health Supports',
    outcomeOptions: ActionPlanItemOutcomeOptions1,
  },
  accessMedicalSupports: {
    categories: ['Non-financial Support'],
    displayValue: 'Access Medical Supports',
    outcomeOptions: ActionPlanItemOutcomeOptions1,
  },
  accessParentingSupport: {
    categories: ['Non-financial Support'],
    displayValue: 'Access Parenting Support',
    usedBy: 'internal',
    outcomeOptions: ActionPlanItemOutcomeOptions1,
  },
  acquireEmployment: {
    categories: ['Employment'],
    displayValue: 'Acquire Employment',
    additionalInformationOptions: ['Casual', 'Full time', 'Part time', 'Self Employment', 'Summer'],
  },
  applyAlbertaFamilyEmploymentTaxCredit: {
    categories: ['Financial Independence'],
    displayValue: 'Apply Alberta Family Employment Tax Credit',
    usedBy: 'serviceProvider',
    outcomeOptions: ActionPlanItemOutcomeOptions1,
  },
  applyAssuredIncomeSeverelyHandicapped: {
    categories: ['Financial Independence'],
    displayValue: 'Apply Assured Income Severely Handicapped (AISH)',
    outcomeOptions: ActionPlanItemOutcomeOptions1,
  },
  applyCanadaChildBenefit: {
    categories: ['Financial Independence'],
    displayValue: 'Apply Canada Child Benefit',
    outcomeOptions: ActionPlanItemOutcomeOptions1,
  },
  applyCanadaPensionPlan: {
    categories: ['Financial Independence'],
    displayValue: 'Apply Canada Pension Plan',
    outcomeOptions: ActionPlanItemOutcomeOptions1,
  },
  applyChildSupportServices: {
    categories: ['Financial Independence'],
    displayValue: 'Apply Child Support Services',
    usedBy: 'internal',
    outcomeOptions: ActionPlanItemOutcomeOptions1,
  },
  applyDayCareSubsidy: {
    categories: ['Financial Independence'],
    displayValue: 'Apply Day Care Subsidy',
    outcomeOptions: ActionPlanItemOutcomeOptions1,
  },
  applyDisabilitySupports: {
    categories: ['Training', 'Employment'],
    displayValue: 'Apply Disability Supports',
    outcomeOptions: ActionPlanItemOutcomeOptions1,
  },
  applyEmploymentInsuranceBenefits: {
    categories: ['Financial Independence'],
    displayValue: 'Apply Employment Insurance Benefits',
    outcomeOptions: ActionPlanItemOutcomeOptions1,
  },
  applyForAlbertaSeniorsBenefits: {
    categories: ['Financial Support'],
    displayValue: 'Apply for Alberta Seniors Benefits',
    outcomeOptions: ActionPlanItemOutcomeOptions1,
  },
  applyFundingParttime: {
    categories: ['Financial Independence'],
    displayValue: 'Apply Funding Part-time',
    additionalInformationOptions: ['Part Time Brs 300', 'Post Sec Loan', 'Skills Invest 5000'],
    // usedBy ?
  },
  applyHealthBenefits: {
    categories: ['Financial Independence'],
    displayValue: 'Apply Health Benefits',
    usedBy: 'internal',
    outcomeOptions: ActionPlanItemOutcomeOptions1,
  },
  applyHousingAssistance: {
    categories: ['Financial Independence'],
    displayValue: 'Apply Housing Assistance',
    outcomeOptions: ActionPlanItemOutcomeOptions1,
  },
  applyIncomeSupport: {
    categories: ['Financial Independence'],
    displayValue: 'Apply Income Support',
    outcomeOptions: ActionPlanItemOutcomeOptions1,
  },
  applyIndigenousFunds: {
    categories: ['Financial Independence'],
    displayValue: 'Apply Indigenous Funds',
    outcomeOptions: ActionPlanItemOutcomeOptions1,
  },
  applyInstitution: {
    categories: ['Career Planning'],
    displayValue: 'Apply Institution',
    usedBy: 'serviceProvider',
    outcomeOptions: ActionPlanItemOutcomeOptions1,
  },
  applyOldAgeSecurity: {
    categories: ['Financial Independence'],
    displayValue: 'Apply Old Age Security - Guaranteed Income Supplement',
    usedBy: 'internal',
    outcomeOptions: ActionPlanItemOutcomeOptions1,
  },
  applyScholarships: {
    categories: ['Financial Independence'],
    displayValue: 'Apply Scholarships',
    outcomeOptions: ActionPlanItemOutcomeOptions1,
  },
  applyStudentLoan: { categories: ['Financial Independence'], displayValue: 'Apply Student Loan' },
  applyToInstitution: { categories: ['Career Planning'], displayValue: 'Apply to Institution', usedBy: 'ae' },
  applyWorkersCompensation: {
    categories: ['Financial Independence'],
    displayValue: 'Apply Workers Compensation',
    outcomeOptions: ActionPlanItemOutcomeOptions1,
  },
  attendAcademicUpgrading: {
    categories: ['Training'],
    displayValue: 'Attend Academic Upgrading',
    additionalInformationOptions: ['GED', 'Grades 10 - 12', 'Tech Entrance Prep', 'UCEP'],
    usedBy: 'ae',
  },
  attendAdditionalSupportActivities: {
    code: 'attendAdditionalSupportActivities',
    categories: ['Employment'],
    displayValue: 'Attend Additional Support Activities',
    additionalInformationOptions: ['Exposure Courses', 'Employment Supports'],
    // additionalControlsByAdditionalInformation: {
    //   'Exposure Courses': {
    //     additionalControls: [
    //       { controlName: 'courseTitle', displayValue: 'Course title', isRequired: true },
    //       { controlName: 'courseProvider', displayValue: 'Course provider', isRequired: true },
    //       { controlName: 'cost', displayValue: 'Cost', isRequired: true },
    //     ],
    //   },
    // },
    usedBy: '!css',
  },
  attendAppointment: {
    categories: ['Career Planning', 'Training', 'Employment', 'Non-financial Support'],
    displayValue: 'Attend Appointment',
    outcomeOptions: ActionPlanItemOutcomeOptions1,
  },
  attendASIP: { categories: ['Training'], displayValue: 'Attend ASIP', usedBy: 'labour' },
  attendBasicSkills: {
    categories: ['Training'],
    displayValue: 'Attend Basic Skills',
    additionalInformationOptions: ['Grades 01 - 03', 'Grades 04 - 06', 'Grades 07 - 09', 'Life Skills Prsnl Mgmnt'],
    usedBy: 'ae',
  },
  attendDigitalEmploymentService: {
    categories: ['Employment'],
    displayValue: 'Attend Digital Employment Service',
    outcomeOptions: ActionPlanItemOutcomeOptions1,
    usedBy: 'internal',
  },
  attendEmploymentInterview: {
    categories: ['Employment'],
    displayValue: 'Attend Employment Interview',
    outcomeOptions: ActionPlanItemOutcomeOptions1,
  },
  attendExposureCourse: {
    categories: ['Employment'],
    displayValue: 'Attend Exposure Course',
    additionalControls: [
      { controlName: 'courseTitle', displayValue: 'Course title', isRequired: true },
      { controlName: 'courseProvider', displayValue: 'Course provider', isRequired: true },
      { controlName: 'cost', displayValue: 'Cost', isRequired: true },
    ],
    code: 'attendExposureCourse',
    exposureCourse: true,
    wcbAcknowledgement: true,
    sinRequired: true,
    labourMarketRequired: true,
    assessmentRequired: true,
    outcomeOptions: ActionPlanItemOutcomeOptions1,
  },
  attendIntegrated: {
    categories: ['Training'],
    displayValue: 'Attend Integrated',
    additionalInformationOptions: ['Immigrant Bridging', 'Regular Integrated'],
    usedBy: '!css',
  },
  attendIntegratedFoundationalPathways: {
    categories: ['Training'],
    displayValue: 'Attend Integrated Foundational Pathways',
    usedBy: '!css',
  },
  attendJobClub: {
    categories: ['Employment'],
    displayValue: 'Attend Job Club',
    usedBy: 'internal',
    outcomeOptions: ActionPlanItemOutcomeOptions1,
  },
  attendJobPlacement: {
    categories: ['Employment'],
    displayValue: 'Attend Job Placement',
    additionalInformationOptions: ['Contract for Persons with Disabilities', 'Regular Job Placement'],
    usedBy: 'serviceProvider',
    outcomeOptions: ActionPlanItemOutcomeOptions3,
    sinRequired: true,
    labourMarketRequired: true,
    assessmentRequired: true,
    wcbAcknowledgement: true,
  },

  attendLanguageTraining: {
    categories: ['Training'],
    displayValue: 'Attend Language Training',
    additionalInformationOptions: ['Enhanced Language', 'ESL'],
    usedBy: '!css',
  },
  attendNAPOrientationSession: {
    categories: ['Non-financial Support'],
    displayValue: 'Attend NAP Orientation Session',
    usedBy: 'internal',
    outcomeOptions: ActionPlanItemOutcomeOptions1,
  },
  attendOccupationalSkills: {
    categories: ['Training'],
    displayValue: 'Attend Occupational Skills',
    usedBy: '!css',
  },
  attendSelfEmployment: { categories: ['Training'], displayValue: 'Attend Self Employment', usedBy: 'labour' },
  attendTransitionToEmploymentService: {
    categories: ['Employment'],
    displayValue: 'Attend Transition to Employment Service',
    usedBy: '!css',
  },
  attendTvp: { categories: ['Training'], displayValue: 'Attend TVP', usedBy: 'external' },
  attendWorkExposure: {
    categories: ['Employment'],
    displayValue: 'Attend Work Exposure',
    usedBy: '!css', //??
  },
  attendWorkforceAdjustmentActivity: {
    categories: ['Training'],
    displayValue: 'Attend Workforce Adjustment Activity',
    additionalInformationOptions: ['Coal Transition', 'Education Voucher'],
    usedBy: '!css',
  },
  attendWorkforceDevelopmentEDA: {
    code: 'attendWorkforceDevelopmentEDA',
    categories: ['Employment'],
    displayValue: 'Attend Workforce Development',
    usedBy: 'serviceProvider',
    outcomeOptions: ActionPlanItemOutcomeOptions3,
    sinRequired: true,
    labourMarketRequired: true,
    assessmentRequired: true,
    wcbAcknowledgement: true,
  },
  attendWorkforceDevelopmentES: {
    code: 'attendWorkforceDevelopmentES',
    categories: ['Employment'],
    displayValue: 'Attend Workforce Development - Employment Supports',
    additionalControls: [
      { controlName: 'description', displayValue: 'Description', isRequired: true },
      { controlName: 'cost', displayValue: 'Cost', isRequired: true },
    ],
    usedBy: 'serviceProvider',
    outcomeOptions: ActionPlanItemOutcomeOptions2,
    sinRequired: true,
    labourMarketRequired: true,
    assessmentRequired: true,
  },
  attendWorkforceDevelopmentEC: {
    code: 'attendWorkforceDevelopmentEC',
    categories: ['Employment'],
    displayValue: 'Attend Workforce Development - Exposure Course',
    additionalControls: [
      { controlName: 'courseTitle', displayValue: 'Course title', isRequired: true },
      { controlName: 'courseProvider', displayValue: 'Course provider', isRequired: true },
      { controlName: 'cost', displayValue: 'Cost', isRequired: true },
      { controlName: 'certificateReceived', displayValue: 'Certificate received', isRequired: true },
    ],
    exposureCourse: true,
    usedBy: 'serviceProvider',
    outcomeOptions: ActionPlanItemOutcomeOptions2,
    sinRequired: true,
    labourMarketRequired: true,
    assessmentRequired: true,
    wcbAcknowledgement: true,
  },
  attendWorkforceDevelopmentWE: {
    code: 'attendWorkforceDevelopmentWE',
    categories: ['Employment'],
    displayValue: 'Attend Workforce Development - Work Exposure',
    additionalControls: [
      { controlName: 'employmentDetails', displayValue: 'Employment details', isRequired: true },
      { controlName: 'contractDetails', displayValue: 'Work exposure contract details', isRequired: true },
    ],
    usedBy: 'serviceProvider',
    outcomeOptions: ActionPlanItemOutcomeOptions3,
    sinRequired: true,
    labourMarketRequired: true,
    assessmentRequired: true,
    wcbAcknowledgement: true,
  },
  attendWorkforceDevelopmentPWD: {
    categories: ['Employment'],
    displayValue: 'Attend Workforce Development - PWD',
    additionalControls: [
      { controlName: 'providerName', displayValue: 'Provider name', isRequired: true },
      { controlName: 'description', displayValue: 'Description', isRequired: true },
    ],
    usedBy: 'serviceProvider',
    outcomeOptions: ActionPlanItemOutcomeOptions3,
    sinRequired: true,
    labourMarketRequired: true,
    assessmentRequired: true,
  },
  attendWorkforceDevelopmentWMS: {
    code: 'attendWorkforceDevelopmentWMS',
    categories: ['Employment'],
    displayValue: 'Attend Workforce Development - Work Maintenance and Support',
    usedBy: '!cs',
    outcomeOptions: ActionPlanItemOutcomeOptions3,
    sinRequired: true,
    labourMarketRequired: true,
    assessmentRequired: true,
  },
  attendWorkplaceTraining: { categories: ['Training'], displayValue: 'Attend Workplace Training', usedBy: '!css' },
  attendWorkshop: {
    categories: ['Career Planning', 'Employment', 'Non-financial Support'],
    displayValue: 'Attend Workshop',
    additionalInformationOptionsByCategory: {
      'Career Planning': [
        'Career Planning',
        'Educational Planning',
        'Labour Market Info',
        'Self Employment',
        'Transitions',
      ],
      Employment: [
        'Cover Letter',
        'Interview Skills',
        'Portfolios',
        'Professional Development',
        'Resume Writing',
        'Work Search',
        'Workplace English',
      ],
      'Non-financial Support': ['Self Asmt and Pers Dev'],
    },
    outcomeOptions: ActionPlanItemOutcomeOptions1,
  },
  ceisReferral: {
    categories: ['Career Planning', 'Employment', 'Financial Support', 'Non-financial Support', 'Training'],
    displayValue: 'CEIS Referral',
    usedBy: 'internal',
  },
  compileAPortfolio: {
    categories: ['Employment'],
    displayValue: 'Compile a Portfolio',
    outcomeOptions: ActionPlanItemOutcomeOptions1,
  },
  completeBusinessPlan: { categories: ['Training'], displayValue: 'Complete Business Plan', usedBy: '!css' },
  completeCareerInvestigationReport: {
    categories: ['Career Planning', 'Training'],
    displayValue: 'Complete Career Investigation Report',
    outcomeOptions: ActionPlanItemOutcomeOptions1,
  },
  completeChangeInCircumstance: {
    categories: ['Financial Independence'],
    displayValue: 'Complete Change in Circumstance',
    usedBy: 'internal',
    outcomeOptions: ActionPlanItemOutcomeOptions1,
  },
  completeEmployabilityAssessment: {
    categories: ['Career Planning'],
    displayValue: 'Complete Employability Assessment',
    usedBy: '!css',
  },
  completeEntranceTest: { categories: ['Training'], displayValue: 'Complete Entrance Test', usedBy: '!css' },
  completeServiceNeedsDetermination: {
    categories: ['Career Planning'],
    displayValue: 'Complete Service Needs Determination',
    usedBy: '!css',
  },
  completeSupplementalSupportActivity: {
    categories: ['Employment'],
    displayValue: 'Complete Supplemental Support Activity - Transition to Employment',
    additionalInformationOptions: ['Employment Readiness Support', 'Short Course', 'Unpaid Work Exposure'],
    usedBy: 'labour',
  },
  conductFollowup: {
    categories: ['Career Planning', 'Training', 'Employment'],
    displayValue: 'Conduct Follow-up',
    additionalInformationOptions: ['030 days', '060 days', '090 days', '180 days', '270 days', '365 days'],
    usedBy: 'internal',
  },
  conductProgressReview: {
    categories: ['Training'],
    displayValue: 'Conduct Progress Review',
    outcomeOptions: ActionPlanItemOutcomeOptions1,
  },
  conductSpecializedAssessment: {
    code: 'conductSpecializedAssessment',
    categories: ['Training', 'Employment'],
    displayValue: 'Conduct Specialized Assessment (I)',
    additionalControls: [
      { controlName: 'description', displayValue: 'Description', isRequired: false },
      { controlName: 'estimatedCost', displayValue: 'Estimated cost', isRequired: false },
      { controlName: 'actualCost', displayValue: 'Actual cost', isRequired: false },
    ],
    isDRESActivity: true,
    sinRequired: true,
    labourMarketRequired: true,
    assessmentRequired: true,
    usedBy: 'internal',
    outcomeOptions: ActionPlanItemOutcomeOptions2,
  },
  considerAcceptableProgress: {
    categories: ['Training'],
    displayValue: 'Consider Acceptable Progress (L)',
    usedBy: 'ae',
  },
  considerCounselToLeave: {
    code: 'considerCounselToLeave',
    categories: ['Training'],
    displayValue: 'Consider Counsel to Leave (I)',
    additionalControls: [
      { controlName: 'courseTitle', displayValue: 'Course title', isRequired: true },
      { controlName: 'courseProvider', displayValue: 'Course provider', isRequired: true },
      { controlName: 'currentEmploymentId', displayValue: 'Current employer' },
    ],
    usedBy: 'notInUseAnyMore',
  },
  considerDRES_EducationalSupport: {
    code: 'considerDRES_EducationalSupport',
    categories: ['Training'],
    displayValue: 'Consider DRES - Educational Support (I)',
    additionalInformationOptions: ['Assistive Svcs', 'Assistive Tech', 'Interpreters/CART', 'Post Sec Grants'],
    additionalControls: [
      { controlName: 'description', displayValue: 'Description', isRequired: false },
      { controlName: 'estimatedCost', displayValue: 'Estimated cost', isRequired: false },
      { controlName: 'actualCost', displayValue: 'Actual cost', isRequired: false },
    ],
    isDRESActivity: true,
    sinRequired: true,
    labourMarketRequired: true,
    assessmentRequired: true,
    outcomeOptions: ActionPlanItemOutcomeOptions2,
    usedBy: 'internal',
  },
  considerDRES_JobSearchSupport: {
    code: 'considerDRES_JobSearchSupport',
    categories: ['Employment'],
    displayValue: 'Consider DRES Job Search Support (I)',
    additionalInformationOptions: ['Assistive Svcs', 'Assistive Tech', 'Interpreters/CART'],
    additionalControls: [
      { controlName: 'description', displayValue: 'Description', isRequired: false },
      { controlName: 'estimatedCost', displayValue: 'Estimated cost', isRequired: false },
      { controlName: 'actualCost', displayValue: 'Actual cost', isRequired: false },
    ],
    isDRESActivity: true,
    sinRequired: true,
    labourMarketRequired: true,
    assessmentRequired: true,
    outcomeOptions: ActionPlanItemOutcomeOptions2,
    usedBy: 'internal',
  },
  considerDRES_WorkplaceSupport: {
    code: 'considerDRES_WorkplaceSupport',
    categories: ['Employment'],
    displayValue: 'Consider DRES Workplace Support (I)',
    additionalInformationOptions: [
      'Assistive Tech',
      'Interpreters/CART',
      'On the Job Supports',
      'Vehicle Modifications',
      'Workplace Modification',
    ],
    additionalControls: [
      { controlName: 'description', displayValue: 'Description', isRequired: false },
      { controlName: 'estimatedCost', displayValue: 'Estimated cost', isRequired: false },
      { controlName: 'actualCost', displayValue: 'Actual cost', isRequired: false },
    ],
    isDRESActivity: true,
    sinRequired: true,
    labourMarketRequired: true,
    assessmentRequired: true,
    outcomeOptions: ActionPlanItemOutcomeOptions2,
    usedBy: 'internal',
  },
  considerDurationException: {
    categories: ['Training'],
    displayValue: 'Consider Duration Exception',
    additionalInformationOptions: [
      '>30 for highly barriered',
      '>30 for reduced course load',
      'Academic Upgrading stream extension',
      'Basic Skills stream extension',
    ],
    usedBy: 'ae',
  },
  considerExposureCourse: {
    categories: ['Employment'],
    displayValue: 'Consider Exposure Course',
    outcomeOptions: ActionPlanItemOutcomeOptions2,
  },
  considerFeepayerRequest: {
    categories: ['Training'],
    displayValue: 'Consider Feepayer Request (I)',
    additionalInformationOptions: ['LINC', 'Skills Upgrading', 'Union Course'],
    usedBy: 'internal',
  },
  considerLackOfProgress: { categories: ['Training'], displayValue: 'Consider Lack of Progress', usedBy: '!css' },
  considerReducedCourseLoad: {
    categories: ['Training'],
    displayValue: 'Consider Reduced Course Load (I)',
    usedBy: 'ae',
  },
  considerRepeatCourse: { categories: ['Training'], displayValue: 'Consider Repeat Course (L)', usedBy: 'ae' },
  considerReturnToTraining: {
    categories: ['Training'],
    displayValue: 'Consider Return to Training (L)',
    usedBy: 'ae',
  },
  determineReadyWillingAble: {
    categories: ['Training', 'Employment'],
    displayValue: 'Determine Ready Willing Able',
  },
  developBudget: {
    categories: ['Financial Independence'],
    displayValue: 'Develop Budget',
    outcomeOptions: ActionPlanItemOutcomeOptions1,
  },
  developNetwork: {
    categories: ['Employment'],
    displayValue: 'Develop Network',
    outcomeOptions: ActionPlanItemOutcomeOptions1,
  },
  developResume: {
    categories: ['Employment'],
    displayValue: 'Develop Resume',
    outcomeOptions: ActionPlanItemOutcomeOptions1,
  },
  developStudySkills: { categories: ['Training'], displayValue: 'Develop Study Skills', usedBy: 'ae' },
  discoverLifestyleImpacts: {
    categories: ['Training', 'Employment'],
    displayValue: 'Discover Lifestyle Impacts',
    outcomeOptions: ActionPlanItemOutcomeOptions1,
  },
  independenceApplyGoodsAndServicesTax: {
    categories: ['Financial Independence'],
    displayValue: 'Independence Apply Goods and Services Tax',
    usedBy: 'internal',
  },
  makeADecision: { categories: ['Career Planning'], displayValue: 'Make a Decision', usedBy: '!css' },
  obtainDocumentation: {
    categories: ['Training', 'Employment'],
    displayValue: 'Obtain Documentation',
    outcomeOptions: ActionPlanItemOutcomeOptions1,
  },
  obtainIdentification: {
    categories: ['Non-financial Support'],
    displayValue: 'Obtain Identification',
    outcomeOptions: ActionPlanItemOutcomeOptions1,
  },
  obtainInformation: {
    categories: ['Career Planning', 'Training', 'Employment', 'Financial Independence', 'Information'],
    displayValue: 'Obtain Information',
    outcomeOptions: ActionPlanItemOutcomeOptions1,
  },
  obtainReferences: {
    categories: ['Employment'],
    displayValue: 'Obtain References',
    outcomeOptions: ActionPlanItemOutcomeOptions1,
  },
  participateJobShadow: {
    categories: ['Career Planning'],
    displayValue: 'Participate Job Shadow',
    outcomeOptions: ActionPlanItemOutcomeOptions1,
  },
  participateJobTryout: {
    categories: ['Employment'],
    displayValue: 'Participate Job Tryout',
    outcomeOptions: ActionPlanItemOutcomeOptions1,
  },
  planBusinessStartUp: { categories: ['Training'], displayValue: 'Plan Business Start Up', usedBy: 'ae' },
  prepareForInterview: {
    categories: ['Employment'],
    displayValue: 'Prepare for Interview',
    outcomeOptions: ActionPlanItemOutcomeOptions1,
  },
  recordResult: { categories: ['Training'], displayValue: 'Record Result', usedBy: 'ae' },
  recordResult2: { categories: ['Employment'], displayValue: 'Record Result', usedBy: 'labour' },
  referralToFinancialLiteracyResources: {
    categories: ['Training'],
    displayValue: 'Referral to financial literacy resources',
    outcomeOptions: ActionPlanItemOutcomeOptions1,
  },
  referralToLINC: {
    categories: ['Non-financial Support'],
    displayValue: 'Referral to LINC',
    usedBy: 'internal',
    outcomeOptions: ActionPlanItemOutcomeOptions1,
  },
  researchEmployers: {
    categories: ['Employment'],
    displayValue: 'Research Employers',
    outcomeOptions: ActionPlanItemOutcomeOptions1,
  },
  researchFunding: {
    categories: ['Career Planning'],
    displayValue: 'Research Funding',
    outcomeOptions: ActionPlanItemOutcomeOptions1,
  },
  researchInstitution: {
    categories: ['Career Planning'],
    displayValue: 'Research Institution',
    outcomeOptions: ActionPlanItemOutcomeOptions1,
  },
  researchOccupations: {
    categories: ['Career Planning'],
    displayValue: 'Research Occupations',
    outcomeOptions: ActionPlanItemOutcomeOptions1,
  },
  researchPrerequisites: {
    categories: ['Career Planning'],
    displayValue: 'Research Prerequisites',
    outcomeOptions: ActionPlanItemOutcomeOptions1,
  },
  researchTransportation: {
    categories: ['Financial Independence'],
    displayValue: 'Research Transportation',
    outcomeOptions: ActionPlanItemOutcomeOptions1,
  },
  seasonalWorker: { categories: ['Training'], displayValue: 'Seasonal Worker', usedBy: '!css' },

  submitJobSearch: { categories: ['Employment'], displayValue: 'Conduct/Submit Job Search' },

  waiveInternationalQualificationAssessmentServiceFee: {
    categories: ['Career Planning', 'Training', 'Employment'],
    displayValue: 'Waive International Qualification Assessment Service Fee',
    outcomeOptions: ActionPlanItemOutcomeOptions1,
  },
  waiveLINCAllowEnglishAsASecondLanguage: {
    categories: ['Training'],
    displayValue: 'Waive LINC Allow English as a Second Language (L)',
    usedBy: 'ae',
  },
  waiveOutOfSchoolRequirement: {
    categories: ['Training'],
    displayValue: 'Waive Out of School Requirement (L)',
    usedBy: 'ae',
  },

  ettsFund: {
    code: 'ettsFund',
    categories: [],
    displayValue: 'This is not an action paln activity, it is used for ETTS funds',
    usedBy: 'cssSystem',
  },
};
