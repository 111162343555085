import { AppConfigService } from '../config/config.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { Observable, take } from 'rxjs';

declare let adspFeedback;

export type AdspFeedbackConfig = {
  view: string;
  correlationId: string;
};
 

@Injectable({
  providedIn: 'root',
})
export class AdspFeedbackService {
  constructor(private http: HttpClient, private titleService: Title, private configService: AppConfigService) { }

  private getIntegrity() {
    const integrityUrl = this.configService.getConfig().ADSP_FEEDBACK_INTEGRITY_URL;
    return this.http.get(integrityUrl);
  }

  public async init(configObservable: Observable<AdspFeedbackConfig>, token: () => Promise<string>) {
    const feedbackJs = this.configService.getConfig().ADSP_FEEDBACK_EXTERNAL_JS;
    if (document.getElementById("adsp-feedback-script") == null && feedbackJs) {
      try {
        this.getIntegrity().pipe(take(1)).subscribe((data) => {
          const script = document.createElement('script');
          script.async = true;
          script.id = 'adsp-feedback-script';
          script.src = `${feedbackJs}?${data['integrity']}`;
          script.integrity = data['integrity']
          script.crossOrigin = "anonymous"
          script.onload = () => this.initFeedbackWidget(configObservable, token);
          document.head.appendChild(script);
        }
        );
      } catch (ex) {
        console.error('Error appending ADSP feedback widget ' + ex);
      }
    }
  }

  private initFeedbackWidget(
    configObservable: Observable<AdspFeedbackConfig>,
    accessToken: () => Promise<string>
  ) {
    const site = this.configService.getConfig().ADSP_FEEDBACK_SITE;
    if (site) {
      let config: AdspFeedbackConfig = { 
        view: this.titleService.getTitle(),
        correlationId: Math.random().toString()
      };

      configObservable.subscribe(a => config = a);
      const getContext = function () {
        return Promise.resolve({ site: site, view: config.view, correlationId: config.correlationId });
      };

      adspFeedback.initialize({
        getContext: getContext,
        getAccessToken: accessToken
      });
    }
  }
}
