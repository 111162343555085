import { RoleLookup } from '../entities/role-lookup.interface';

/*
Priority number controls which rolename displays.  Lowest number takes priority if a user has 2 or more roles.
For example, if a Regional Assigner (88) is also a CEC Supervisor (20), the title will show as CEC Supervisor.
*/

export class CssRoles {
  public static readonly CSSAdmin: RoleLookup = { displayValue: 'Admin', code: 'CSSAdmin', priority: 1 };
  public static readonly CSSService: RoleLookup = { displayValue: 'Service Acct', code: 'CSSService', priority: 2 };
  public static readonly CompassAdmin: RoleLookup = {
    displayValue: 'Compass Administrator',
    code: 'CompassAdmin',
    priority: 199,
  };

  public static readonly ExecDirector: RoleLookup = {
    displayValue: 'Executive Director',
    code: 'ExecDirector',
    priority: 2,
  };

  public static readonly CSSManager: RoleLookup = {
    displayValue: 'Manager',
    code: 'CSSManager',
    priority: 10,
  };

  public static readonly RegionalAssigner: RoleLookup = {
    displayValue: 'Regional Assigner',
    code: 'RegionalAssigner',
    priority: 88,
  };
  public static readonly ProvincialAssigner: RoleLookup = {
    displayValue: 'Provincial Assigner',
    code: 'ProvincialAssigner',
    priority: 89,
  };

  public static readonly OutreachCEC: RoleLookup = { displayValue: 'Outreach CEC', code: 'OutreachCEC', priority: 98 };
  public static readonly DRES: RoleLookup = { displayValue: 'DRES CEC', code: 'DRES', priority: 80 };
  // csssm 5858, make priority:80 which lower than Assigner's 88 and 89
  public static readonly CEC: RoleLookup = { displayValue: 'CEC', code: 'CEC', priority: 80 };
  public static readonly CECSupervisor: RoleLookup = {
    displayValue: 'CEC Supervisor',
    code: 'CECSupervisor',
    priority: 20,
  };
  public static readonly CareerAdvisor: RoleLookup = {
    displayValue: 'Career Advisor',
    code: 'CareerAdvisor',
    priority: 80,
  };

  public static readonly SFSC: RoleLookup = { displayValue: 'SFSC', code: 'SFSC', priority: 99 };
  public static readonly SFSCSupervisor: RoleLookup = {
    displayValue: 'SFSC Supervisor',
    code: 'SFSCSupervisor',
    priority: 20,
  };

  public static readonly ASCCSupervisor: RoleLookup = {
    displayValue: 'ASCC Supervisor',
    code: 'CSDSupervisor',
    priority: 20,
  };
  public static readonly CSD: RoleLookup = { displayValue: 'ASCC', code: 'CSD', priority: 99 };
  public static readonly ASCC: RoleLookup = { displayValue: 'ASCC', code: 'ASCC', priority: 99 };

  public static readonly ARCAdvisor: RoleLookup = { displayValue: 'ARC Advisor', code: 'ARCAdvisor', priority: 99 };
  public static readonly ARCLead: RoleLookup = { displayValue: 'ARC Lead Hand', code: 'ARCLead', priority: 98 };
  public static readonly ARCSupervisor: RoleLookup = {
    displayValue: 'ARC Supervisor',
    code: 'ARCSupervisor',
    priority: 97,
  };

  public static readonly AdminAssistant: RoleLookup = {
    displayValue: 'Admin assistant',
    code: 'AdminAssistant',
    priority: 99,
  };

  public static readonly ISCC: RoleLookup = {
    displayValue: 'ISCC',
    code: 'ISCC',
    priority: 99,
  };

  public static readonly ISDE: RoleLookup = {
    displayValue: 'ISDE',
    code: 'ISDE',
    priority: 99,
  };

  public static readonly ChildSupportWorker: RoleLookup = {
    displayValue: 'Child Support Worker',
    code: 'ChildSupportWorker',
    priority: 99,
  };

  public static readonly CSSInvestigator: RoleLookup = {
    displayValue: 'Investigator',
    code: 'CSSInvestigator',
    priority: 99,
  };

  public static readonly InvestigationsAdmin: RoleLookup = {
    displayValue: 'Investigations Administrator',
    code: 'InvestigationsAdmin',
    priority: 99,
  };

  public static readonly HealthFuneralBenefitsWorker: RoleLookup = {
    displayValue: 'HFBW',
    code: 'HealthFuneralBenefitsWorker',
    priority: 99,
  };

  public static readonly AccountingOfficer: RoleLookup = {
    displayValue: 'Accounting officer',
    code: 'AccountingOfficer',
    priority: 99,
  };

  public static readonly CSSObserver: RoleLookup = {
    displayValue: 'CSS Observer',
    code: 'CSSObserver',
    priority: 99,
  };

  public static readonly ExternalServiceProvider: RoleLookup = {
    displayValue: 'Service Provider',
    code: 'ExternalServiceProvider',
    priority: 99,
  };
  public static readonly ContractProcurementSpecialist: RoleLookup = {
    displayValue: 'Contract and Procurement Specialist',
    code: 'ContractProcurementSpecialist',
    priority: 99,
  };
  public static readonly DEEDAnalysisViewer: RoleLookup = {
    displayValue: 'DEED Analysis Viewer',
    code: 'DEEDAnalysisViewer',
    priority: 199,
  };

  public static readonly AISHPartner: RoleLookup = { displayValue: 'AISH Partner', code: 'AISHPartner', priority: 2 };
  public static readonly AISHTester: RoleLookup = { displayValue: 'AISH Tester', code: 'AISHTester', priority: 3 };
  public static readonly AISHMonitor: RoleLookup = { displayValue: 'AISH Monitor', code: 'AISHMonitor', priority: 4 };

  public static readonly IESPartner: RoleLookup = { displayValue: 'IES Partner', code: 'IESPartner', priority: 2 };
  public static readonly IESTester: RoleLookup = { displayValue: 'IES Tester', code: 'IESTester', priority: 3 };
  public static readonly FBPartner: RoleLookup = { displayValue: 'FB Partner', code: 'FBPartner', priority: 2 };
  public static readonly FBTester: RoleLookup = { displayValue: 'FB Tester', code: 'FBTester', priority: 3 };
  public static readonly FSCDITester: RoleLookup = { displayValue: 'FSCDI Tester', code: 'FSCDITester', priority: 2 };

  public static readonly AuthManager: RoleLookup = { displayValue: 'Auth Manager', code: 'AuthManager', priority: 199 };

  public static readonly HomelessAdmin: RoleLookup = {
    displayValue: 'Homeless Admin',
    code: 'HomelessAdmin',
    priority: 1,
  };
  public static readonly ProgramAdvisor: RoleLookup = {
    displayValue: 'Program Advisor',
    code: 'ProgramAdvisor',
    priority: 2,
  };
  public static readonly HomelessShelter: RoleLookup = {
    displayValue: 'Shelter',
    code: 'HomelessShelter',
    priority: 2,
  };
  public static readonly HomelessReportViewer: RoleLookup = {
    displayValue: 'Report Viewer',
    code: 'HomelessReportViewer',
    priority: 2,
  };

  public static readonly HomelessRoles = [
    CssRoles.HomelessAdmin,
    CssRoles.ProgramAdvisor,
    CssRoles.HomelessShelter,
    CssRoles.HomelessReportViewer,
  ];

  public static readonly IntakeRoles = [
    CssRoles.CSSAdmin,
    CssRoles.CSSManager,
    CssRoles.RegionalAssigner,
    CssRoles.ProvincialAssigner,
    CssRoles.CEC,
    CssRoles.CECSupervisor,
    CssRoles.OutreachCEC,
    CssRoles.ASCC,
    CssRoles.ASCCSupervisor,
    CssRoles.ExecDirector,
  ];

  public static readonly CaseValues = [
    CssRoles.CSSAdmin,
    CssRoles.CSSService,
    CssRoles.CSSManager,
    CssRoles.RegionalAssigner,
    CssRoles.ProvincialAssigner,
    CssRoles.CEC,
    CssRoles.DRES,
    CssRoles.CECSupervisor,
    CssRoles.CareerAdvisor,
    CssRoles.OutreachCEC,
    CssRoles.AdminAssistant,
    CssRoles.ARCAdvisor,
    CssRoles.ARCLead,
    CssRoles.ARCSupervisor,
    CssRoles.ASCC,
    CssRoles.ASCCSupervisor,
    CssRoles.ISCC,
    CssRoles.ISDE,
    CssRoles.SFSC,
    CssRoles.SFSCSupervisor,
    CssRoles.ExecDirector,
    CssRoles.ChildSupportWorker,
    CssRoles.CSSObserver,
    CssRoles.HealthFuneralBenefitsWorker,
    CssRoles.CSSInvestigator,
    CssRoles.AccountingOfficer,
    CssRoles.AuthManager,
    CssRoles.ExternalServiceProvider,
    CssRoles.ContractProcurementSpecialist,
    CssRoles.CompassAdmin,
    CssRoles.InvestigationsAdmin,
    CssRoles.DEEDAnalysisViewer,
  ];

  public static readonly CaseExternalRoles = [CssRoles.ExternalServiceProvider];

  public static readonly CaseInternalRoles = [
    ...CssRoles.CaseValues.filter((r) => !CssRoles.CaseExternalRoles.find((r2) => r2 === r)),
  ];

  public static readonly AISHValues = [CssRoles.CSSAdmin, CssRoles.AISHPartner, CssRoles.AISHTester];
  public static readonly IESValues = [CssRoles.CSSAdmin, CssRoles.IESPartner, CssRoles.IESTester];
  public static readonly FBValues = [CssRoles.CSSAdmin, CssRoles.FBPartner, CssRoles.FBTester];
  public static readonly FSCDIValues = [CssRoles.CSSAdmin, CssRoles.FSCDITester];

  public static readonly AllValues = [
    ...CssRoles.CaseValues,
    ...CssRoles.AISHValues.filter((r) => r.code !== CssRoles.CSSAdmin.code),
    ...CssRoles.IESValues.filter((r) => r.code !== CssRoles.CSSAdmin.code),
    ...CssRoles.FBValues.filter((r) => r.code !== CssRoles.CSSAdmin.code),
    ...CssRoles.HomelessRoles.filter((r) => r.code !== CssRoles.CSSAdmin.code),
  ];
}
