import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { RoleLookup, User } from '@common/entities';
import { MaintenanceService } from '../service/maintenance.service';
import { Utilities } from '@common/utils';
import { ContextService } from '@common/ui/shared-components';

export const MaintenanceBypassRoles = new InjectionToken<RoleLookup[]>('Maintenance Bypass Roles');
export const MaintenanceRedirectRoute = new InjectionToken<string>('Maintenance Error Route');

const DEFAULT_MAINTENANCE_ROUTE = '/serviceError';

/**
 * Redirects the user to the provided `maintenanceRoute` if a maintenence window is set in the app's config and is current.
 * Users with the provided `bypassRoles' will not be redirected.
 */
@Injectable({
  providedIn: 'root',
})
export class MaintenanceRedirectGuard {
  constructor(
    private router: Router,
    private maintenanceService: MaintenanceService,
    private contextService: ContextService,
    @Optional() @Inject(MaintenanceBypassRoles) private bypassRoles: RoleLookup[],
    @Optional() @Inject(MaintenanceRedirectRoute) private maintenanceRoute: string
  ) {}

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    return this.runGuard(state);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    return this.runGuard(state);
  }

  private async runGuard(state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    const currentRoute = state.url.split('?')[0];
    const redirectRoute = this.maintenanceRoute || DEFAULT_MAINTENANCE_ROUTE;
    if (currentRoute !== redirectRoute) {
      const result = await this.shouldRedirect();
      if (result) return this.router.parseUrl(redirectRoute);
    }

    return true;
  }

  private async shouldRedirect(): Promise<boolean> {
    const maintenance = await this.maintenanceService.scheduledMaintenance();
    if (!maintenance.active) return false;
    const hasBypassRole = await this.userHasBypassRole();

    return !hasBypassRole;
  }

  private async userHasBypassRole(): Promise<boolean> {
    if (!this.bypassRoles) return false;

    // const user = await this.authService.getUserProfileWithRoles();
    const user = await this.contextService.getContextUserProfile(false); //pull profile from token instead of making API call
    return user && Utilities.isInRoles(user as User, this.bypassRoles);
  }
}
