import { Lookup } from '../../constants/lookup.interface';
import { IntakeLabelCollection } from '../../entities/intake-label.interface';
import { IntakeLabel } from '../../entities/intake-label.interface';
// import { MinistryList } from '../../constants/ministry-list';

export interface ReviewLabelsLookup extends IntakeLabel {
  label: string;
  houseoldSituation?: boolean;
  requiresSchedule?: boolean;
  orderedListItem?: number;
  excludeFromPdf?: boolean;
}

export const IesReviewLabels: { [name: string]: ReviewLabelsLookup } = {
  declarationItem1: {
    label: 'The information on this application describes the financial and household situation for:',
    houseoldSituation: true,
    orderedListItem: 1,
  },
  declarationItem2: {
    label: `When I am receiving Income Support benefits, I will report as soon as possible to my worker:
    <ul>
      <li>all money that I or anyone in my household receive from any source.</li>
      <li>any change in my household situation.</li>
      <li>any change in things my spouse/partner or I own.</li>
    </ul>`,
    orderedListItem: 2,
  },
  declarationItem3: {
    label: `I understand that:
    <ul>
      <li>Alberta Community and Social Services (CSS) has the right to apply for child/adult support for me and/or my dependent children and that the Government of Alberta may retain any of the support owing to the government.</li>
      <li>I must notify and/or receive consent from Child Support Services, CSS when taking my own support action.</li>
      <li>when required I must help the Government of Alberta to pursue support for me and my dependent children. </li>
    </ul>`,
    orderedListItem: 3,
  },
  declarationItem4: {
    label:
      'I understand CSS has the right to recover benefits I receive to which I am not entitled. This includes those issued due to administrative error.',
    orderedListItem: 4,
  },
  declarationItem5: {
    label: 'I understand I have the right to appeal a decision within 30 days of being told of the decision.',
    orderedListItem: 5,
  },
  declarationItem6: {
    label: `I understand that CSS and/or CSS contracted services may get information about my financial, work, educational, medical and/or household situation to:
    <ul>
      <li>determine my eligibility for Income Support, or</li>
      <li>conduct a review or investigation relating to eligibility or continuing eligibility for these program benefits.</li>
    </ul>
    I ask any person, agency, institution or other source to give the required information to CSS and/or CSS contracted services. `,
    orderedListItem: 6,
  },
  declarationItem7: {
    label: `I understand that relevant personal information may be shared with other Government of Alberta programs and services, including contracted services, and with community partners.`,
    orderedListItem: 7,
  },
  declarationItem8: {
    label: `I understand CSS may conduct reviews or investigations relating to financial eligibility for this program. I acknowledge that I must provide information or documents as required by CSS to verify any statement made on this application or information given to my worker.`,
    orderedListItem: 8,
  },
  declarationItem9: {
    label: `I understand that I must comply with program expectations to continue to be eligible for benefits.`,
    orderedListItem: 9,
  },
  declarationItem10: {
    label: `If I am a Financial Administrator or other legal representative, I understand what this Declaration means as it applies to the applicant.`,
    orderedListItem: 10,
  },
  declarationItem11: {
    label: `I understand that I must complete the Canada Revenue Agency consent to be eligible for benefits.`,
    orderedListItem: 11,
  },
  declarationItem12: {
    label: `I understand that giving false or incomplete information or not advising of changes in my situation may result in any or all of the following: termination or suspension of benefits, repayment of benefits received, and/or laying of a charge under the <em>Income and Employment Supports Act</em> <span>or the</span> <em>Criminal Code of Canada.</em>`,
    orderedListItem: 12,
  },
  agreeDeclarationContent: {
    label: `<strong>
        I understand that regardless of who in my family is the designated reporter for the Automated Reporting for Clients system, it is my responsibility to ensure any changes in my situation have been reported to the department.
    </strong>`,
  },
  declarationItem13: {
    label: 'Confirm documents included with this application:',
    requiresSchedule: true,
  },
  agreeDeclaration: {
    label: 'I declare that the information on this application and required schedules is true and complete.',
    excludeFromPdf: true,
  },
};

export const IesReviewCheckboxLabels: IntakeLabelCollection = {
  householdMe: {
    label: 'Me',
  },
  householdDependent: {
    label: 'My dependents',
  },
  requiresScheduleA: {
    label: 'Schedule A - Child Support Services',
  },
  requiresScheduleC: {
    label: 'Schedule C - Declaration of 18 or 19 year old dependent(s)',
  },
};
