//IMPORTANT: These methods currently exist in authentication-service.ts
//But authentication-service.ts will be moved to an NPM package so repositories outside of this one can access those methods.
//The methods in context-service.ts are ones de-coupled from authentication-service.ts that are not common and apply only to context profile.

import { Injectable } from '@angular/core';
import { getPrimaryRole } from '../../../shared/helpers/utils';
import { Office, User } from '@common/entities';
import { AuthenticationService, AuthUserProfile } from '@govalta-emu/keycloak-auth-service';

@Injectable({
  providedIn: 'root',
})
export class ContextService {
  id;
  constructor(private authService: AuthenticationService) {}

  //ensure if profile is pulled from API, that it only pulls it once.
  private _profileFromApi: Promise<AuthUserProfile> | null = null;
  async getProfileFromApi(): Promise<AuthUserProfile> {
    if (!this._profileFromApi) {
      this._profileFromApi = this.authService.getUserProfileWithRoles().catch((error) => {
        // Reset _profileFromApi to null if the call fails, allowing retry on subsequent calls
        this._profileFromApi = null;
        throw error;
      });
    }
    return this._profileFromApi;
  }

  //setting forceLegacy to default to true so that by default it continues to behave the way it was for other projects (pulling from API instead of token).
  async getContextUserProfile(forceLegacy = true) {
    const checkToken = await this.authService.checkRefreshToken();
    if (checkToken === null) {
      //token no longer valid
      console.log('Error: Refresh token failed and no longer valid');
      return null;
    }

    const token = this.authService.getKeycloakInstance();
    const tokenParsed = token.tokenParsed;
    const roles = tokenParsed?.realm_access?.roles ?? [];
    let profile = this.authService.tokenUserInfoToProfile(token.userInfo, roles); //attributes will exist in userInfo if configured/mapped in keycloak as a client scope
    if (!profile || forceLegacy) {
      profile = await this.getProfileFromApi(); //this method calls keycloak API (loadUserInfo). just be aware.
    }

    if (!profile) {
      return null;
    }
    const user = {
      _id: profile.userid,
      name: profile.firstName && profile.lastName ? `${profile.firstName} ${profile.lastName}` : null,
      roles: roles,
      primaryRole: getPrimaryRole(roles)?.displayValue ?? null,
      title: profile.title ?? '',
      location: profile.location ?? 'Alberta',
      email: profile.email ?? null,
      // office: this.getOfficeInfo(profile._rawProfile),
      phone: profile._rawProfile?.phone ?? null,
      agency: profile._rawProfile?.agency ?? null,
      district: profile._rawProfile?.district ?? null,
    } as User;

    //TODO: See if we are still using this? I think maybe office was moved out of attributes?
    if (profile?._rawProfile) {
      user.office = this.getOfficeInfo(profile._rawProfile);
    } else if (profile?.office) {
      user.office = this.getOfficeInfo(profile.office);
    }

    return user;
  }

  private getOfficeInfo(data): Office {
    if (!data?.address && !data?.officeAddress) return null;
    return {
      streetAddress: data.address?.street_address ?? data.officeAddress ?? null,
      city: data.address?.locality ?? data.officeCity ?? null,
      province: 'Alberta',
      postalCode: data.address?.postal_code ?? data.officePostalCode ?? null,
      phone: data.phone ?? data.officePhone ?? null,
      fax: data.fax ?? data.officeFax ?? null,
    };
  }
}
