export const EventAudienceTypes = [
  { _id: 'community', name: 'Community agency' },
  { _id: 'disabled', name: 'Disabled Albertans' },
  { _id: 'educators', name: 'Educators' },
  { _id: 'employees', name: 'Employees' },
  { _id: 'employers', name: 'Employers' },
  { _id: 'externalSP', name: 'External Service Provider' },
  { _id: 'public', name: 'General Public' },
  { _id: 'GOAStaff', name: 'GOA Staff' },
  { _id: 'immigrants', name: 'Immigrants' },
  { _id: 'indigenous', name: 'Indigenous Albertans' },
  { _id: 'internalSP', name: 'Internal Service Provider' },
  { _id: 'industry', name: 'Industry Sector' },
  { _id: 'lowIncome', name: 'Low Income Albertans' },
  { _id: 'parents', name: 'Parents' },
  { _id: 'students', name: 'Students' },
  { _id: 'youth', name: 'Youth' },
  { _id: 'women', name: 'Women' },
];
