import { IntakeLabelCollection } from '../../entities/intake-label.interface';
import { MinistryList } from '../../constants/ministry-list';

export const IesConsentLabels: IntakeLabelCollection = {
  agreePrivacy: {
    label: 'I have read and agree to the Privacy Notice.',
  },
  agreePrivacyContent: {
    label: `The personal information you provide is being collected to determine your eligibility for different social-based supports and benefits offered by the Government of Alberta under Alberta Supports. If
you choose to apply, the personal information you provide will then be used and disclosed in the
application process, for ongoing eligibility verification, and for delivery of those programs, benefits
or services offered by the Government of Alberta under Alberta Supports, if eligibility is confirmed.<br /><br />
    The personal information provided to Alberta Supports is collected, used and disclosed under the
authority of sections 33-40 of the Freedom of Information and Protection of Privacy Act and various
statutes establishing the programs included in Alberta Supports.<br /><br />
    If you have questions about the collection of your personal information you can review the
authorizing legislation, or speak to an Alberta Supports Coordinator at the Alberta Supports Contact
Centre at <a href="tel:1-877-644-9992">1-877-644-9992</a> or <a href="tel:780-644-9992">780-644-9992</a> (Edmonton).`,
  },
  agreeCraConsent: {
    label: 'I have read and agree to the Canada Revenue Agency consent above.',
  },
  agreeCraConsentContent: {
    label: `I consent to the release, by Canada Revenue Agency to an official of Alberta Community and Social Services, of information from my income tax returns and other taxpayer information about me whether supplied by me or a third party. The information will be relevant to, and will be used solely for the purpose of determining, verifying and/or auditing my/our eligibility and for the general administration and enforcement of programs under the Income and Employment Supports Act. This consent is valid for the taxation year prior to the year of signature, the current taxation year and for each subsequent taxation year for which assistance is requested.`,
  },
};
