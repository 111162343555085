import { takeUntil } from 'rxjs/operators';
import { Component, OnDestroy, Input, Output, EventEmitter, OnInit, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpEventType } from '@angular/common/http';
import { Document, DocumentList } from '@common/entities';
import { FileService } from '../../shared/services/file/file.service';
import { ControlContainer, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UploadLookup } from '@common/constants';
import { SELECT_ONE_TEXT } from '@common/constants';
import { HostListener } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { CanvasState } from '../canvas-state';
import { PDFDocument } from 'pdf-lib';

@Component({
  selector: 'd3-drag-drop-worker',
  templateUrl: './drag-drop-worker.component.html',
  styleUrls: ['./drag-drop-worker.component.scss'],
})
export class D3DragDropWorkerComponent implements OnInit, OnDestroy {
  @Input() parentId: string;
  @Input() dataCy: string;
  @Input() alwaysShowOptOut = false;
  @Input() allowProtectedPDFs = false;

  multiple = true;
  selectOneText = SELECT_ONE_TEXT;
  private _documentsUploaded: DocumentList;
  isOptoutChecked = false;

  @Input() set documentsUploaded(value: DocumentList) {
    if (value) {
      this._documentsUploaded = value;
      this.setFormData();
    }
  }
  @Input() maxFileSize: number;
  _accept: string;
  @Input() set accept(value: string) {
    if (value) {
      this._accept = value;
      const accepts = value.split('.');
      const last = accepts.pop();
      this.acceptDisplay = accepts.join('') + ' or ' + last;
    }
  }

  _markAsTouched: boolean = null;
  @Input() set markAsTouched(value: boolean) {
    if (value !== null && value !== undefined && this._markAsTouched === null) {
      this._markAsTouched = value;
    }
  }

  //gives us the ability to initialize document list at the component level
  _componentInitialized: boolean;
  @Input() set componentInitialized(value: boolean) {
    if (value === true && !this._componentInitialized) {
      //only does this once
      this.initializeDocumentList();
      this._componentInitialized = true;

      const control = this.formGroup?.controls[this.documentCategory.key];
      const initState = control?.errors?.required ? 'error' : null;
      //check if the control is in error required state. if so set it to error to initialize
      this.saveState(initState);
    }
  }

  @Input() deleteMessage: string;

  @Input() documentCategory: { key: string; value: UploadLookup };
  @Input() multipleDocument: boolean;
  @Input() categoryLabel = 'Proof of';
  @Input() uploadedDocuments: Document[]; //this object gives us access to the files in document-list.component
  @Input() initialCanvasState: string;
  @Input() skipUploadMore: boolean;
  @Output() started = new EventEmitter<Document[]>();
  @Output() uploadInProgress = new EventEmitter<Document>();
  @Output() fileUploaded = new EventEmitter<Document>();
  @Output() errorOccurred = new EventEmitter<Document>();
  @Output() completed = new EventEmitter<void>();
  @Output() optOutChanged = new EventEmitter<{ optOut: boolean; category: string }>();
  @Output() saveCanvasState = new EventEmitter<CanvasState>();
  //initializeCategory

  uploading = false;
  documentTypeFormControl: UntypedFormControl;
  uploadMore = false;
  acceptDisplay: string;
  formGroup: UntypedFormGroup;
  public uploadButtonId: string = uuidv4();
  optOutCategories = [];
  documentInProgress: Document;
  documentsInProgress: Document[];
  documentsUploadComplete: Document[];
  private destroy$ = new Subject<void>();
  initOptOut: string[] = null;
  initWithError: false;

  activateCanvas = true;
  canvasStateEnabled = 'enabled';
  canvasStateDisabled = 'disabled';
  canvasStateError = 'error';
  canvasStateCurrent = this.canvasStateEnabled;
  canvasError = false;
  fileEventAction = false; //for some reason cypress tests are able to do both file drag and file click uploads at the same time. Adding checks to prohibit this behavior
  selectedDocumentType = '';

  constructor(
    public fileService: FileService,
    private fb: UntypedFormBuilder,
    private controlContainer: ControlContainer,
    private cdr: ChangeDetectorRef
  ) {}
  ngOnInit(): void {
    this.formGroup = this.controlContainer.control as UntypedFormGroup;
    this.formGroup.addControl(
      this.documentCategory.key,
      this.fb.control(null, {
        validators: this.documentCategory.value?.isOptional ? [] : [Validators.required],
      })
    );
    this.formGroup.addControl('optOut', this.fb.control(this._documentsUploaded?.optOut, null));
    this.formGroup.addControl('activated', this.fb.control(this._documentsUploaded?.activated, null));
    this.setFormData();

    this.documentTypeFormControl = this.fb.control(
      this.documentCategory.value?.documentTypes && this.documentCategory.value?.documentTypes.length === 1
        ? this.documentCategory.value.documentTypes[0]
        : ''
    );

    if (!this._componentInitialized) {
      //need to set initOptOut for the initial opt out value.
      setTimeout(() => {
        if (this._markAsTouched === true) {
          this.formGroup.markAllAsTouched();
        } else if (this._markAsTouched === false) {
          this.formGroup.markAsUntouched();
        }
        this.initializeDocumentList();
        this.checkCanvasState();
      }, 0);
    }
  }

  initializeDocumentList() {
    if (this.formGroup?.controls) {
      this.formGroup.controls.optOut.setValue(this._documentsUploaded.optOut);
      this.initOptOut = this._documentsUploaded.optOut;
      this.initWithError = this.hasErrorAndTouched;
      if (this.initWithError) {
        this.canvasError = true;
      }

      const fileCount = this.formGroup.controls[this.documentCategory.key]?.value?.length;

      if (fileCount > 0 || this.initOptOut) {
        //if opt out is enabled should be marked as touched to ensure invalids show error messages.
        //if they have files, this upload control should be marked as touched so if it's removed the error will display
        if (this.formGroup.controls[this.documentCategory.key]) {
          this.formGroup.controls[this.documentCategory.key].markAsTouched();
        }
      }

      if (this.initOptOut?.includes(this.documentCategory.key) && this.documentTypeFormControl) {
        this.documentTypeFormControl.disable();
      }

      this.setValidation();
      this.cdr.detectChanges();
    }
  }

  saveState(setState?: string) {
    if (setState) {
      this.canvasStateCurrent = setState;
    }
    const canvasState = { key: this.documentCategory.key, state: this.canvasStateCurrent } as CanvasState;
    this.saveCanvasState.emit(canvasState);
    if (canvasState.state === this.canvasStateError) {
      this.canvasError = true;
    }
  }

  ngOnDestroy() {
    this.formGroup.removeControl(this.documentCategory.key);
    this.destroy$.next();
    this.destroy$.complete();
  }

  addRemoveOptOutCategory(category: string, add = true, emitOptOutChange = false) {
    const optOutCategories = (this.formGroup.controls.optOut?.value as string[]) ?? [];
    if (!optOutCategories.includes(category) && add) {
      //add
      optOutCategories.push(category);
      this.formGroup.controls.optOut.setValue(optOutCategories);
    } else if (!add && optOutCategories.includes(category)) {
      //remove
      const removeIndex = optOutCategories.indexOf(category);
      optOutCategories.splice(removeIndex, 1);
      this.formGroup.controls.optOut.setValue(optOutCategories);
    }

    if (emitOptOutChange) {
      const optOutParam = { optOut: add, category: category };
      this.optOutChanged.emit(optOutParam);
    }
  }

  onOptOutChanged(optOut, category: string) {
    const optOutChecked = optOut.checked;
    this.addRemoveOptOutCategory(category, optOutChecked, true);

    if (optOutChecked) {
      this.documentTypeFormControl.disable();
    } else {
      this.documentTypeFormControl.enable();
    }
    this.setValidation();
  }

  optOutDisabled(category: string) {
    const fileCount = this.formGroup.controls[category]?.value?.length;
    return fileCount > 0;
  }

  setValidation() {
    if (this.formGroup?.controls[this.documentCategory.key]) {
      if (
        this.documentCategory.value?.isOptional ||
        this.formGroup.controls.optOut?.value?.includes(this.documentCategory.key)
      ) {
        this.formGroup.controls[this.documentCategory.key].setValidators(null);
      } else {
        this.formGroup.controls[this.documentCategory.key].setValidators([Validators.required]);
      }
      this.formGroup.updateValueAndValidity();
    }
  }

  onUploadMore() {
    if (this.documentCategory.value.documentTypes.length > 1) this.documentTypeFormControl.setValue('');
    this.uploadMore = true;
    this.activateCanvas = true;
    this.canvasError = false;
  }

  private setFormData() {
    if (this.formGroup) {
      if (this.formGroup.controls[this.documentCategory.key]) {
        this.formGroup.controls[this.documentCategory.key].setValue(
          (this._documentsUploaded?.documents &&
            this._documentsUploaded.documents.length &&
            this._documentsUploaded.documents) ||
            null
        );
      }
      this.setValidation();
    }
  }

  private async prepareFilesList(files: Array<File>) {
    this.activateCanvas = false;
    this.documentsInProgress = [];
    this.documentsUploadComplete = [];

    //documentCategory.value.documentTypes.length > 1

    //if no dropdowns items are selected, check if there is only 1 item (results in dropdown being not visible)
    //if there is only 1 item, select that item
    if (!this.selectedDocumentType && this.documentCategory.value.documentTypes.length === 1) {
      this.selectedDocumentType = this.documentCategory.value.documentTypes[0];
    }

    for (const file of files) {
      this.documentInProgress = {
        documentCategory: this.documentCategory.key,
        // documentType: this.documentTypeFormControl.value,  //dropdown is getting cleared before documents are being saved
        documentType: this.selectedDocumentType,
        name: file.name,
        size: file.size,
        completed: false,
        progress: 1,
        file,
      } as Document;

      this.documentsInProgress.push(this.documentInProgress);
      const fileError = await this.getUploadFileError(this.documentInProgress);

      if (fileError) {
        this.documentInProgress.completed = true;
        this.documentInProgress.errors = fileError;
        this.documentInProgress.dateUploaded = new Date();
        this.documentsUploadComplete.push(this.documentInProgress);
        this.errorOccurred.emit(this.documentInProgress);

        this.checkIfUploadCompleted();
      }
    }
    if (files.length > 0) {
      this.startUploadingFiles();
    }
  }

  displayOptional() {
    if (!this.documentCategory.value?.isOptional) return '';
    if (this.documentCategory.value?.isOptional) {
      if (!this.documentCategory.value?.suppressOptionalLabel) return '(Optional)';
      if (this.documentCategory.value?.suppressOptionalLabel) return '';
    }
  }

  private async getUploadFileError(document: Document) {
    if (document.file.size > this.maxFileSize || document.file.size === 0) {
      return {
        maxFileSize: this.maxFileSize.toString(),
      };
    }
    const ext = document.name.split('.').pop();
    const fileExtension = '.' + ext.toUpperCase();
    if (
      this._accept &&
      (!fileExtension ||
        (!this._accept.endsWith(fileExtension) &&
          this._accept.indexOf(fileExtension + ' ') === -1 &&
          this._accept.indexOf(fileExtension + ',') === -1))
    ) {
      return {
        fileExtension: 'true',
      };
    }
    const mimeType = document.file.type;
    if (!mimeType) {
      return {
        mimeType: 'true',
      };
    }

    //check protected file
    if (fileExtension === '.PDF' && !this.allowProtectedPDFs) {
      try {
        const arrayBuffer = await this.readFileAsArrayBuffer(document.file);
        const uploadedPdf = await PDFDocument.load(arrayBuffer, { ignoreEncryption: true });

        if (uploadedPdf.isEncrypted) {
          // this.setError(document, {
          //   fileLoad: 'PDF is password protected. Please remove the password and try again.',
          // });
          // return false;
          return {
            protectedPdf: 'true',
          };
        }
      } catch (err) {
        // this.setError(document, {
        //   fileLoad: 'PDF is invalid. Please select another file.',
        // });
        // return false;
        return {
          invalidPdf: 'true',
        };
      }
    }

    return null;
  }

  private startUploadingFiles() {
    this.started.emit(this.documentsInProgress);
    this.uploading = true;
    this.documentsInProgress.forEach((doc) => {
      this.uploadFile(doc);
    });
  }

  private completeFileUpload(file: Document) {
    file.completed = true;
    file.errors = { upload: 'true' };
    file.dateUploaded = new Date();
    this.documentsUploadComplete.push(file);
    this.errorOccurred.emit(file);
    this.checkIfUploadCompleted();
  }

  private uploadFile(file: Document) {
    if (file.errors) {
      file.completed = true;
      this.errorOccurred.emit(file);
      this.checkIfUploadCompleted();
      return;
    }

    console.log('!upload started!');
    this.fileService
      .upload(this.parentId, file.file)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (result) => {
          //file.key = result.key;
          switch (result.type) {
            case HttpEventType.Sent:
              this.uploadInProgress.emit(file);
              break;
            case HttpEventType.UploadProgress:
              file.progress = Math.round((100 * result.loaded) / result.total);
              this.uploadInProgress.emit(file);
              break;
            case HttpEventType.Response:
              file.key = result.key;
              file.progress = 100;
              file.completed = true;
              file.dateUploaded = new Date();
              this.documentsUploadComplete.push(file);
              this.uploadInProgress.emit(file);
              this.fileUploaded.emit(file);
              this.checkIfUploadCompleted();
              break;
          }
        },
        () => {
          this.completeFileUpload(file);
        }
      );
  }

  private checkIfUploadCompleted() {
    if (this.documentsInProgress.length === this.documentsUploadComplete.length) {
      console.log('!upload complete!');
      this.uploading = false;
      if (this.skipUploadMore) {
        this.onUploadMore(); //auto trigger upload more
      } else {
        this.uploadMore = false;
      }
      this.selectedDocumentType = ''; //ensure selected document type is reset after successful upload
      this.completed.emit();
    }
  }
  get hasUploadedDocument(): boolean {
    const hasUploadedDocument = this._documentsUploaded?.documents?.length > 0;
    return hasUploadedDocument;
  }

  getOptOutChecked(documentCategory: string) {
    const optOutCategories = this.formGroup.controls.optOut.value as string[];
    const fileCount = this.formGroup.controls[documentCategory]?.value?.length;
    if (fileCount > 0) {
      //only need to trigger emit if opt out for this category is selected and we need to uncheck
      const triggerEmit = optOutCategories?.includes(documentCategory);
      this.addRemoveOptOutCategory(documentCategory, false, triggerEmit);
    }
    this.checkCanvasState();

    if (optOutCategories?.length > 0) {
      this.isOptoutChecked = optOutCategories?.includes(documentCategory);
    }
    return this.isOptoutChecked;
  }

  getUploaderLabel(ariaLabel = false) {
    //Terence: Also looking like this may not follow this pattern and may be different per document upload.
    const categoryTitleOverride = this.documentCategory.value?.categoryTitleOverride;
    const defaultLabel = `${this.categoryLabel} ${this.documentCategory.value?.displayValue?.toLowerCase()}`.trim();
    const optionalLabel = this.displayOptional();

    let uploaderLabel = `  
    <span>${defaultLabel}</span>
    <span class="optional-label">${optionalLabel}</span>`;

    if (categoryTitleOverride) {
      uploaderLabel = `
      <span>${categoryTitleOverride}</span>
      <span class="optional-label">${optionalLabel}</span>`;
    }

    if (ariaLabel) {
      let label = categoryTitleOverride;
      if (!label) {
        label = defaultLabel;
      }
      if (this.dataCy) {
        label = `${label} - ${this.dataCy}`;
      }
      return label;
    }

    return uploaderLabel;
  }

  getDataCy(dataCy: string = null, documentCategory: string = null, identifier: string = null) {
    let objDataCy = '';
    if (dataCy) {
      objDataCy = dataCy;
    }
    if (documentCategory) {
      if (objDataCy) {
        objDataCy += '-';
      }
      objDataCy += documentCategory;
    }
    if (identifier) {
      if (objDataCy) {
        objDataCy += '-';
      }
      objDataCy += identifier;
    }
    return objDataCy;
  }

  onDocumentTypeChange() {
    this.canvasError = false;
    this.selectedDocumentType = this.documentTypeFormControl.value;
  }

  fileUploadEvent($event, source: string) {
    if (this.fileEventAction) {
      //file event action is locked because another upload event is currently active
      return false;
    }
    let filesArray = [];
    if (source === 'canvas') {
      filesArray = $event;
    } else if (source === 'browse') {
      filesArray = $event.target.files;
    }
    this.fileEventAction = true;
    if (this.canvasStateCurrent === this.canvasStateDisabled) {
      this.fileEventAction = false; //release action lock
      return false;
    } else {
      this.prepareFilesList(filesArray)
        .then(() => {
          this.fileEventAction = false;
          return true;
        })
        .catch((ex) => {
          console.log('Error occurred in prepareFilesList: ' + ex);
          this.fileEventAction = false;
          return false;
        });
    }
  }

  displayCanvas(): boolean {
    const documentTypesLength = this.documentCategory?.value?.documentTypes?.length;

    return !!this.selectedDocumentType || documentTypesLength === 1 || documentTypesLength === 0;
  }

  changeCanvasState(state: string) {
    this.canvasStateCurrent = state;
    return this.canvasStateCurrent;
  }

  checkCanvasState() {
    // this.canvasStateCurrent = this.canvasStateEnabled;

    this.canvasStateCurrent = this.initialCanvasState;
    if (this.canvasStateCurrent === undefined) {
      this.canvasStateCurrent = this.canvasStateEnabled;
    }

    const optOutCategories = this.formGroup.controls['optOut']?.value ?? [];
    const optOutChecked = optOutCategories.includes(this.documentCategory.key);
    if (
      this.uploading ||
      (this.documentCategory.value.documentTypes.length > 0 && !this.documentTypeFormControl.value) ||
      optOutChecked
    ) {
      //force disabled state
      // this.canvasStateCurrent = this.canvasStateDisabled;
      this.saveState(this.canvasStateDisabled);
    } else if (!this.formGroup.valid && this.canvasError) {
      //force error state
      // this.canvasStateCurrent = this.canvasStateError;
      this.saveState(this.canvasStateError);
    } else if (this.canvasStateCurrent === this.canvasStateDisabled) {
      //console.log('disabled state');
      //is in disabled state but should not be
      this.saveState(this.canvasStateEnabled);
    } else if (this.uploadMore) {
      //if it is in uploadMore state (meaning documents have already been uploaded), then it should be enabled.
      this.saveState(this.canvasStateEnabled);
    }
  }

  fileErrors(property: string) {
    const error = this.uploadedDocuments?.filter((doc) => {
      let hasError = false;
      if (!doc.isDeleted) {
        if (property) {
          const docProperty = doc?.errors ? doc.errors[property] : null;
          hasError = docProperty !== null && docProperty !== undefined && docProperty !== 'false';
        } else {
          hasError = doc?.errors !== null && doc?.errors !== undefined;
        }
      }
      return hasError;
    });
    if (error?.length > 0) {
      return true;
    }
  }

  private readFileAsArrayBuffer(file: File): Promise<ArrayBuffer> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as ArrayBuffer);
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  }

  anyFileErrors() {
    const hasAnyFileErrors =
      this.fileErrors('maxFileSize') ||
      this.fileErrors('fileExtension') ||
      this.fileErrors('mimeType') ||
      this.fileErrors('protectedPdf') ||
      this.fileErrors('invalidPdf');
    return hasAnyFileErrors;
  }

  showAddDocumentsButton() {
    return (
      this.hasUploadedDocument && !this.uploadMore && this.multipleDocument && !this.uploading && !this.skipUploadMore
    );
  }

  get isUploadEnabled() {
    const uploadEnabled =
      !this.uploading && (this.documentCategory.value.documentTypes.length === 0 || this.documentTypeFormControl.value);
    if (uploadEnabled && !this.activateCanvas) {
      this.onUploadMore();
    }
    return this.activateCanvas;
  }

  get optOutLabel() {
    let label = this.documentCategory.value?.optOutLabel;
    if (this.documentCategory.value?.optOutLabelTransform) {
      label = this.documentCategory.value?.optOutLabelTransform;
    }
    return label;
  }

  get getRequiredErrorMessage() {
    let label = this.documentCategory.value?.requiredErrorOverride;
    if (this.documentCategory.value?.requiredErrorOverrideTransform) {
      label = this.documentCategory.value?.requiredErrorOverrideTransform;
    }
    if (!label) {
      //default error message
      label = `${this.categoryLabel} ${this.documentCategory.value?.displayValue?.toLowerCase()} is required.`;
    }
    return label;
  }

  get hasErrorAndTouched() {
    const errorAndTouched =
      this.formGroup.controls[this.documentCategory.key]?.touched &&
      this.formGroup.controls[this.documentCategory.key].errors?.required;
    if (errorAndTouched && !this.initOptOut) {
      //optout should be set to visible (if optoutlabel is set) if there is an error and touched.
      this.initOptOut = [];
    }
    return errorAndTouched;
  }
  get canvasUploaderState() {
    this.checkCanvasState();
    let state = this.canvasStateCurrent;
    if (state === this.canvasStateError && !this.hasAnyErrorMessage) {
      //if there is no error message present, show enabled state always;
      state = this.canvasStateEnabled;
    }
    // else if (state === this.canvasStateEnabled && this.hasErrorMessage) {
    //   //if there is an error and is enabled state always show error state
    //   state = this.canvasStateError;
    // }
    return state;
  }

  //we only want to show the required error message
  //if this formgroup has and error and has been touched.
  //if the canvas state is not enabled. enabled state should never show the error even if the formgroup has an error as there are actions that reset the error state
  //if there is an error message to be shown
  get showRequiredErrorMessage() {
    if (
      this.hasErrorAndTouched &&
      !this.isOptoutChecked &&
      // this.canvasStateCurrent !== this.canvasStateEnabled &&
      (this.canvasStateCurrent === this.canvasStateError || this.canvasStateCurrent === this.canvasStateDisabled) &&
      this.getRequiredErrorMessage
    ) {
      return true;
    }
    return false;
  }

  get hasAnyErrorMessage() {
    const hasFileError = this.anyFileErrors();
    const hasRequiredError = this.showRequiredErrorMessage;
    return hasFileError || hasRequiredError;
  }

  @HostListener('keydown', ['$event'])
  onkeyup(event) {
    if (event.keyCode === 13 || event.keyCode === 32) {
      // 13 = enter 32 = space
      const element = document.getElementById(this.uploadButtonId);
      if (element) {
        element.click();
      }
    }
  }
}
